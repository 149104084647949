import React from "react";

import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import CfcustomisableworkflowbyusersController, { Props} from './CfcustomisableworkflowbyusersController'
import Sidebars from "../../../components/src/SideBars.web";
// Customizable Area End
export default class UserAdditionalDomain extends CfcustomisableworkflowbyusersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <Sidebars isNestedOpenBar={this.state.isNestedOpenBar} />
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
