export const imageRound1 = require("../assets/imageRound1.png");
export const imageRound2 = require("../assets/imageRound2.png");
export const imageRound3 = require("../assets/imageRound3.png");
export const imageRound4 = require("../assets/imageRound4.png");
export const imageRound5 = require("../assets/imageRound5.png");
export const imageRound6 = require("../assets/imageRound6.png");
export const Image_logo1 = require("../assets/Image_logo1.png");
export const Image = require("../assets/Image.jpeg");
export const imageround = require("../assets/imageround.png");
export const rightImage = require("../assets/rightImage.png");
export const Frame62 = require("../assets/Frame62.png");
export const Frame47 = require("../assets/Frame47.png");
export const image01 = require("../assets/image01.png");
export const image02 = require("../assets/image02.png");
export const image03 = require("../assets/image03.png");
export const image07 = require("../assets/image07.png");
export const Frame = require("../assets/Frame.jpg");
export const lines = require("../assets/lines.png");
export const Frame11 = require("../assets/Frame11.png");
export const Frame12 = require("../assets/Frame12.png");
export const Frame78 = require("../assets/Frame78.png");
export const Frame22 = require("../assets/Frame22.png");
export const computer = require("../assets/computer.png");
export const card = require("../assets/card.png");
export const service = require("../assets/service.png");
export const Frame66 = require("../assets/Frame66.png");
export const Frame68 = require("../assets/Frame68.png");
export const image_logo = require("../assets/image_logo.png");
export const image08 = require("../assets/image08.png")
