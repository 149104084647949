import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  gettoken1: string;
  accountId: string;
  password: string;
  token: any;
  confirmPassword: string;
  email: string;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  checkedRememberMe: boolean;
  TermsAndConditionsAccept: boolean;
  TermsAndConditionsDec: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  buttonChange: string;
  passwordStrength: string;
  open: boolean;
  confirmButtom: boolean;
  popupModal: boolean;
  recoveryButton: boolean;
  isLengthValid: boolean;
  isUpperCaseValid: boolean;
  isLowerCaseValid: boolean;
  isNumberValid: boolean;
  error: any;
  loginError:string;
  forgotPasswordError:string
  openModal: boolean;
  passwordsMatch: boolean;
  checkInBox: boolean;
  showResendEmail: boolean
  forgotPasswordParam: boolean;
  signupParam: boolean;
  forgotPassword:boolean;
  successMessage:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  createAccountApiCallId: string = "";
  forgotPasswordApiCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      gettoken1: "",
      accountId: "",
      email: "",
      error: null,
      loginError:"",
      forgotPasswordError:"",
      token: "",
      password: "",
      confirmPassword: "",
      enablePasswordField: false,
      enableConfirmPasswordField: false,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      buttonChange: "SignUp",
      open: false,
      confirmButtom: false,
      popupModal: false,
      recoveryButton: false,
      isLengthValid: false,
      isUpperCaseValid: false,
      isLowerCaseValid: false,
      isNumberValid: false,
      passwordStrength: "",
      TermsAndConditionsAccept: true,
      TermsAndConditionsDec: false,
      openModal: false,
      passwordsMatch: true,
      checkInBox: false,
      showResendEmail: false,
      forgotPasswordParam: true,
      signupParam: false,
      forgotPassword:true,
      successMessage:''
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const storeToken: any = localStorage.getItem('token')
    const parseToken = storeToken !=="undefined" && JSON.parse(storeToken)
    this.setState({ token: parseToken })
    const datavalue = window.location.pathname
    if (datavalue === "/EmailAccountLoginBlock") {
      this.setState({ buttonChange: "Login" })
    } else if (datavalue === "/SignUpPage/false") {
      this.setState({ buttonChange: "SignUp" })
    }

    // Customizable Area End
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };
  setEmail = (text: string) => {
    this.setState({error:'' ,forgotPasswordError:"" , loginError:''})
    this.setState({
      email: text,
    });
  };
  setConfirmPassword = (confirmPassword: string) => {
    this.setState({ confirmPassword }, () => {
      const passwordsMatch = this.state?.password === confirmPassword;
      this.setState({ passwordsMatch });
    });
    this.setState({
      confirmPassword: confirmPassword,
      isLengthValid: this.state?.confirmPassword?.length >= 8,
      isUpperCaseValid: /[A-Z]/.test(this.state?.confirmPassword),
      isLowerCaseValid: /[a-z]/.test(this.state?.confirmPassword),
      isNumberValid: /\d/.test(this.state?.confirmPassword),
    });
    if (
      this.state.isLengthValid &&
      this.state.isUpperCaseValid &&
      this.state.isLowerCaseValid &&
      this.state.isNumberValid
    ) {
      this.setState({ passwordStrength: configJSON.strongPassword })

    } else {
      this.setState({ passwordStrength: configJSON.weakPassword });
    }
  }

  setPassword = (text: string) => {

    this.setState({
      password: text,
      isLengthValid: this.state?.password?.length >= 8,
      isUpperCaseValid: /[A-Z]/.test(this.state?.password),
      isLowerCaseValid: /[a-z]/.test(this.state?.password),
      isNumberValid: /\d/.test(this.state?.password),
      loginError:''
    });

    // Check overall password strength
    if (
      this.state?.isLengthValid &&
      this.state?.isUpperCaseValid &&
      this.state?.isLowerCaseValid &&
      this.state?.isNumberValid
    ) {
      this.setState({ passwordStrength: configJSON.strongPassword })

    } else {
      this.setState({ passwordStrength: configJSON.weakPassword });
    }
  };
  handleNavigate = () => {
    localStorage.setItem('email', JSON.stringify({ email: this.state.email }));
    this.props.navigation.navigate("TermsAndConditions");
  }
  handleAccept = () => {
    this.setState({ TermsAndConditionsAccept: true })
    const userEmail = JSON.parse(localStorage.getItem('email')!)?.email;
    localStorage.setItem('checkedRememberMe', JSON.stringify(this.state.TermsAndConditionsAccept));
    this.setState({ email: userEmail });
    this.props.navigation.navigate("SignUpPage", {
      orgTab: "false",
    });
  }
  handlSignupPage = () => {
    localStorage.removeItem('checkedRememberMe')
    this.props.navigation.navigate("SignUpPage", {
      orgTab: "false",
    })
  }
  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  handleLoginPage = () => {
    this.props?.navigation?.navigate("EmailAccountLoginBlock")
  }

  handleClose = () => {
    this.setState({ checkInBox: false, confirmButtom: false , open:false })
  };
  handleCloseModal = () => {
    this.setState({ openModal: false })
    this.props?.navigation?.navigate("EmailAccountLoginBlock")
  }
  handleConfirmEmail = () => {
    const { email, checkedRememberMe } = this.state;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!email || !emailPattern.test(email)) {
      this.setState({ error: 'Invalid email address' });
      this.setState({ checkedRememberMe: false });
    } else if (!checkedRememberMe) {
      this.setState({ error: 'You have to agree with Privacy Policy and Terms & Conditions to Sign up' });
    } else {
      this.setState({ error: null });
      localStorage?.removeItem('email');
      localStorage?.removeItem('checkedRememberMe')
      this.handleOpenConfirmPassword();
    }

  }
  handleResetPassword = (actionType?: any) => {
    const { password, confirmPassword } = this.state;
    if (!password || !confirmPassword) {
      this.setState({ passwordsMatch: false });

    } else if (password !== confirmPassword) {
      this.setState({ passwordsMatch: false });
    } else {
      this.setState({ passwordsMatch: true, confirmButtom: false, popupModal: false, email: '', checkedRememberMe: false });

      this.handleSignUp(actionType);
    }
  }
  handleEmailChange = (e: any) => {
    this.setState({ email: e.target.value });
  };

  resetModalClose = () => {
    this.setState({ popupModal: true , recoveryButton: true })
    this.setState({ open: false })
  }

  handleRecovery = () => {
    const { email } = this.state;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!email || !emailPattern.test(email)) {
      this.setState({ error: 'Invalid email address' });
    } else {    
      this.handleRecoveryEmail()
    }
  }


  handleRecoveryEmail = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const data = {
      email: this.state.email
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.forgotPasswordEndPoint}=${this.state.forgotPasswordParam}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleOpenConfirmPassword = () => {
    this.setState({ showResendEmail: true })
    const header = {
      "Content-Type": "application/json",
    };

    const data = {
      type: "email_account",
      email: this.state.email,
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts'
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ open: true })
  };

  confirmemail = () => {
    this.setState({ checkInBox: false, popupModal: true, confirmButtom: true })
    this.props.navigation.navigate("CreatePassword")
  }

  handleSignUp = (actionValue: any) => {
    const header = {
      "Content-Type": "application/json",
    };

    const data = {
      token: actionValue ? this.state.gettoken1 : this.state?.token,
      password: this.state?.password,
      password_confirmation: this.state?.confirmPassword,
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      actionValue ?  `${configJSON.signupEndPoint}=${this.state.forgotPassword}`:`${configJSON.signupEndPoint}=${this.state.signupParam}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  doLogIn = () => {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      type: "email_account",
      email: this.state.email,
      password: this.state.password,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_login/logins'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.props?.navigation?.navigate("/LandingPageWeb")
  }

  createApiFunction=(responseJson:any)=>{
    if (responseJson.data.type === 'account') {
      this.setState({ openModal: true ,  successMessage:responseJson.meta.success})
      setTimeout(() => {
        if (this.state.accountId === responseJson.data.id) {
          this.props?.navigation?.navigate("EmailAccountLoginBlock");
        }
      }, 3000)
    } else {
      this.setState({ token: responseJson.meta.token, accountId: responseJson.data.id, checkInBox: true  , successMessage:responseJson.meta.success})
      this.saveLoggedInUserData(responseJson);
      localStorage.setItem('token', JSON.stringify(responseJson.meta.token));
    }
  }

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiEmailLoginCallId) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        localStorage.setItem('token', JSON.stringify(responseJson.meta.token));
        localStorage.setItem('email',(this.state.email))
        this.setState({  password: '' })
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        this.props?.navigation?.navigate("Cfcustomisableworkflowbyusers")
      } else if(responseJson?.errors?.length > 0) {
        this.setState({loginError : responseJson?.errors[0]?.password ? responseJson?.errors[0]?.password: responseJson?.errors[0]?.email})
      }

      if (apiRequestCallId === this.createAccountApiCallId) {
        this.createApiFunction(responseJson)
      }
      else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.forgotPasswordApiCallId != null &&
        this.forgotPasswordApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setState({ gettoken1: responseJson.meta.token, open: true , accountId: responseJson.data.id})
      }
      else{
        this.setState({forgotPasswordError:responseJson?.errors[0]?.email})
      }
    
    }


    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
