import React, { ChangeEvent } from "react";

import {
    Box,
    TextField,
    Checkbox,
    Button,
    InputLabel,
    Typography,
    Grid,
    Modal,
    
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import { profile_image, Close, closeIcon,generalLogo } from "./assets";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Select, { ValueType } from "react-select";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End
import CfcustomisableworkflowbyusersController, {
    CountryProps,
    Props,
    
} from "./CfcustomisableworkflowbyusersController";
import Sidebars from "../../../components/src/SideBars.web";

export default class GeneralInformation extends CfcustomisableworkflowbyusersController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    // Customizable Area Start

    closeModal = () => {
        this.setState({
            isModalOpen: false,
        });
    };

    handleOpenModal = () => { this.setState({ isModalOpen: true }) }

    handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file : FileList | null = e?.target?.files;
        if (file && file.length > 0) {
            const imageURL = URL.createObjectURL(file[0]);
            this.setState({ base64Image: imageURL });
            
        } 
        this.uploadProfilePicture(file);
            this.getProfileInformation();
        }






    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box style={{ background: "#fff", width: "100%", height: "140px" }}>
                    <Box>
                        <img
                            src={generalLogo}
                            style={{
                                height: "95px",
                                width: "336px",
                                padding: "1%"
                            }} />
                    </Box>
                </Box>
                <Sidebars isNestedOpenBar={this.state.isNestedOpenBar} />
                <Box>
                    <Box className="scrollbar-container1" style={{ overflowY: "scroll" }}>
                        <Box className="content" style={{ height: "100%" }}>
                            <Box className="welcome-box">
                                <Typography className="welcome-heading">Welcome to your account, {this.state.user_firstName}</Typography>
                                <Typography className="welcome-Subheading">Manage customer account, security, support level, and users. You can also check the emails received for your services. </Typography>
                                <Box className="user-information">
                                    <Box>
                                        <Box>
                                            <Box className="edit-btn">
                                                <Box>

                                                    <Typography className="user-information-heading">USER INFORMATION </Typography>

                                                    <Typography className="information-text">Your profile photo and name appear when you log in to ENGEEM PLATFORM, for Apps and services.</Typography>
                                                </Box>

                                                <Button data-testId="editBTN" className="editBTN" onClick={this.UserInfoNavigation}>
                                                    {this.state.UserForms ? "Cancel" : "Edit"}
                                                </Button>

                                            </Box>
                                            {this.state.UserForms &&
                                                <Box className="editForm">
                                                    <Grid container spacing={5}>
                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                            <Box style={styles.inputlablepassword}>
                                                                <InputLabel style={styles.lableStyle}>
                                                                    First name *
                                                                </InputLabel>
                                                            </Box>
                                                            <Box
                                                                data-testId=""
                                                                style={styles.emailPAswordRoot}
                                                            >
                                                                <TextField className="editform-input"
                                                                    placeholder=" First name"
                                                                    data-testId="FirstName"
                                                                    value={this.state.firstName}
                                                                    onChange={(e) => this.setState({ firstName: e.target.value })}

                                                                ></TextField>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                            <Box style={styles.inputlablepassword}>
                                                                <InputLabel style={styles.lableStyle}>
                                                                    Last name *
                                                                </InputLabel>
                                                            </Box>
                                                            <Box
                                                                data-testId=""
                                                                style={styles.emailPAswordRoot}
                                                            >
                                                                <TextField
                                                                    placeholder=" Last name"
                                                                    data-testId="LastName"
                                                                    className="editform-input"
                                                                    value={this.state.lastName}
                                                                    onChange={(e) => this.setState({ lastName: e.target.value })}
                                                                ></TextField>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box style={styles.inputlablepassword}>
                                                                <InputLabel style={styles.lableStyle}>
                                                                    Company *
                                                                </InputLabel>
                                                            </Box>
                                                            <Box
                                                                data-testId=""
                                                                style={styles.emailPAswordRoot}
                                                            >
                                                                <TextField
                                                                    placeholder=" Company"
                                                                    data-testId="Company"
                                                                    className="editform-input"
                                                                    value={this.state.company}
                                                                    onChange={(e) => this.setState({ company: e.target.value })}

                                                                ></TextField>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>

                                                            <Box>
                                                                <Box style={styles.inputlablepassword}>
                                                                    <InputLabel style={styles.lableStyle}>
                                                                        Phone number *
                                                                    </InputLabel>
                                                                </Box>
                                                                <Box
                                                                    data-testId=""
                                                                    style={styles.emailPAswordRoot}
                                                                >
                                                                    <TextField
                                                                        type="number"
                                                                        placeholder="Phone number"
                                                                        data-testId="PhoneNumber"
                                                                        className="editform-input"
                                                                        value={this.state.phoneNumber}
                                                                        onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                                                                    ></TextField>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Box
                                                                style={{
                                                                    display: "flex",
                                                                    paddingBottom: "1%",
                                                                }}
                                                            >
                                                                <InputLabel style={styles.lableStyle}>
                                                                    Country *
                                                                </InputLabel>
                                                            </Box>
                                                            <Box style={{ width: "100%", border: '1px solid #70b3b7' }}>
                                                                <Select style={{ width: '100%' }}
                                                                    data-testId="Country"
                                                                    options={this.state.countryList.map((countryName) => ({
                                                                        value: countryName,
                                                                        label: countryName,
                                                                      }))}
                                                                      value={{ value: this.state.country, label: this.state.country }}
                                                                      onChange={(selectedOption) => {
                                                                        const selectedCountryName = selectedOption?.value;
                                                                        this.setState({ country: selectedCountryName });
                                                                      }}
                                                                     />

                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Box style={styles.checkBoxRoot}>
                                                                <Checkbox data-testId="CheckBox" style={{ color: "#1391AB" }} defaultChecked={this.state.isAcceptNotification} value={this.state.isAcceptNotification} onChange={(e) => this.setState({ isAcceptNotification: !this.state.isAcceptNotification })} />
                                                                <Typography>
                                                                    I accept to receive email notifications for
                                                                    Order and Project Management purposes.
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        {/* <Grid item xs={12}>
                                                            <Box
                                                                style={{
                                                                    display: "flex",
                                                                    boxShadow: "4px 4px 10px rgba(0,0,0,0.2)",
                                                                    width: "100%",
                                                                    gap: "2%",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Typography>
                                                                    To proceed, grant permission for email
                                                                    notifications.
                                                                </Typography>
                                                            </Box>
                                                        </Grid> */}
                                                        <Grid item xs={12}>
                                                            {this.state.errorMessage && this.state.errorMessage.length > 0 && <Box
                                                                className="flexContainerWithBoxShadow" onClick={() => this.setState({ errorMessage: '' })}>
                                                                <img src={closeIcon} />
                                                                <Typography style={{ whiteSpace: 'nowrap' }}>{this.state.errorMessage}</Typography>
                                                            </Box>}
                                                            <Box data-testId="SaveButton" style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={() => this.updateUserDetails()}>
                                                                <Button
                                                                    style={{
                                                                        background: this.allFieldsFilled() && this.state?.isAcceptNotification ? "#1391AB" : "#A8B1B3",
                                                                        color: "#232638",
                                                                        width: "135px",
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </Box>

                                                        </Grid>
                                                    </Grid>




                                                </Box>

                                            }


                                            {
                                                this.state.UserInfoFomrsClose === true &&
                                                <Box className="profile-info-box">
                                                    <Box className="profile-box-white">

                                                        <Box className="profile-picture">
                                                            <div className="profile-box-pic">
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <InputLabel>Profile Picture
                                                                    </InputLabel>
                                                                    <div className="profile-img-box">
                                                                        {this.state.base64Image ?

                                                                            <img
                                                                                src={this.state.base64Image}
                                                                                alt="Converted to base64"
                                                                                style={{ maxWidth: "100%" }}

                                                                            />
                                                                            : <img src={profile_image} alt="" width="80" />}

                                                                    </div>

                                                                </div>
                                                                {/* <input
                                                                    data-testId="uploadPhoto"
                                                                    accept="image/*"
                                                                    style={{ display: 'none' }}
                                                                    id="contained-button-file"
                                                                    type="file"
                                                                    onChange={this.handleFileChange}
                                                                />
                                                                <label htmlFor="contained-button-file">
                                                                    <Button className="btn-profile" variant="contained" color="primary" component="span">
                                                                        Upload++ Image
                                                                    </Button>
                                                                </label> */}
                                                                <div style={{ borderRadius: "7px" }}>
                                                                    <div>
                                                                        <Modal open={this.state.isModalOpen} onClose={this.closeModal}>
                                                                            <Box className="modalboxupload">
                                                                                <div>
                                                                                    <Box className="thanks-box">
                                                                                        <img data-testId="handleClose" onClick={this.closeModal} src={Close} />
                                                                                    </Box>
                                                                                    {this?.state?.base64Image ? (
                                                                                        <div className="uploadImageBox">
                                                                                            <div className="uploadImage">
                                                                                                <img
                                                                                                    src={this.state.base64Image}
                                                                                                    alt="Converted to base64"
                                                                                                    style={{ maxWidth: "100%" }}
                                                                                                // onLoad={this.uploadProfilePicture}
                                                                                                />
                                                                                            </div>
                                                                                            <div style={{ marginBlockStart: "20px" }}>
                                                                                                <label htmlFor="uploadInput" className="customUploadButton">
                                                                                                    Change photo
                                                                                                </label>
                                                                                                <input
                                                                                                    type="file"
                                                                                                    id="uploadInput"
                                                                                                    data-testId="Upinput"
                                                                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleFileChange(e)}
                                                                                                    accept="image/*"
                                                                                                    style={{ display: "none" }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className="uploadImageBox">
                                                                                                <div className="uploadImage">
                                                                                                    <p>+</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <p className="drag-center">Drag and Drop your image or</p>
                                                                                            <div>
                                                                                                <label htmlFor="uploadInput" className="customUploadButton">
                                                                                                    Upload photo
                                                                                                </label>
                                                                                                <input
                                                                                                    type="file"
                                                                                                    id="uploadInput"
                                                                                                    data-testId="Upinput"
                                                                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleFileChange(e)}

                                                                                                    accept="image/*"
                                                                                                    style={{ display: "none" }}
                                                                                                />
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </Box>
                                                                        </Modal>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <Box className="remove-btn">
                                                                <Button
                                                                    data-testId="deleteImage"
                                                                    onClick={this.deleteImage}
                                                                    className="btn-profile RemoveBtn">Remove</Button>
                                                                <Button className="btn-profile ChangeBtn" onClick={this.handleOpenModal}>Change</Button>
                                                            </Box>
                                                        </Box>

                                                        <Box className="profile-picture-data">
                                                            <label>First name</label>
                                                            <div className="data-right">{this.state.user_firstName}</div>
                                                        </Box>

                                                        <Box className="profile-picture-data">
                                                            <label>Last name</label>
                                                            <div className="data-right">{this.state.user_lastName}</div>
                                                        </Box>

                                                        <Box className="profile-picture-data">
                                                            <label>Email</label>
                                                            <div className="data-right">{this.state.storeEmail}</div>
                                                        </Box>

                                                        <Box className="profile-picture-data">
                                                            <label>Company</label>
                                                            <div className="data-right">{this.state.user_company}</div>
                                                        </Box>

                                                        <Box className="profile-picture-data">
                                                            <label>Phone Number</label>
                                                            <div className="data-right">{this.state.user_phoneNumber}</div>
                                                        </Box>

                                                        <Box className="profile-picture-data">
                                                            <label>Privileges</label>
                                                            <div className="data-right">Admin</div>
                                                        </Box>

                                                        <Box className="profile-picture-data">
                                                            <label>Country</label>
                                                            <div className="data-right">{this.state.user_country}</div>
                                                        </Box>


                                                    </Box>
                                                </Box>}

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = ({
    lableStyle: {
        color: "var(--Old-Gray, #232638)",
        // fontFamily: "Roboto Condensed",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px", marginBottom: '10px'
    },
    emailPAswordRoot: { display: "flex", height: "2.7em" },
    inputlablepassword: { display: "flex", },
    buttonroot: { display: "flex", justifyContent: "center" },
    checkBoxRoot: { display: "flex", alignItems: "center" },
})
// Customizable Area End