import React from "react";
import {
  Box,
  TextField,
  Checkbox,
  Button,
  InputLabel,
  Typography,
  Grid

  // Customizable Area Start
  , Accordion,
  AccordionSummary,
  AccordionDetails,
  // Customizable Area End
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { generalLogo } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },

  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },

  },

});
// Customizable Area End

import CfcustomisableworkflowbyusersController, {
  Props,
} from "./CfcustomisableworkflowbyusersController";
import Sidebars from "../../../components/src/SideBars.web";
export default class GeneralInformationTechnologyomainProfile extends CfcustomisableworkflowbyusersController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  //@ts-ignore
  CheckboxGroup = ({ options,onChange }) => {
    return (
      <Box>
        {options?.map((option: any, index: any) => (
          <React.Fragment key={index}>
            <Checkbox style={{ color: "#1391AB" }} onChange={() => onChange && onChange(option)} />
            <label>{option}</label>
            <br />
          </React.Fragment>
        ))}
      </Box>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={{ width: "100%", background: "#fff", height: "140px" }}>
          <Box>
            <img
              style={{
                height: "95px",
                width: "336px",
                padding: "1%"
              }}
              src={generalLogo} />
          </Box>
        </Box>
        <Sidebars isNestedOpenBar={this.state.isNestedOpenBar} />
        <Box>
          <Box style={{ overflowY: "scroll" }} className="scrollbar-container1" >
            <Box style={{ height: "max-content" }} className="content">
              <Box className="welcome-box">
                <Typography className="welcome-heading">
                  Welcome to your account, Linda{" "}
                </Typography>
                <Typography className="welcome-Subheading">
                  Manage customer account, security, support level, and users.
                  You can also check the emails received for your services.{" "}
                </Typography>
                <Box className="user-information">
                  <Box>
                    <Box>
                      <Typography className="user-information-heading">
                        TECHNOLOGY DOMAIN PROFILE{" "}
                      </Typography>
                      <Typography className="information-text">
                        Your profile photo and name appear when you log in to
                        ENGEEM PLATFORM, for Apps and services.
                      </Typography>

                      {
                        this.state.Company_Info === true &&

                        <Box className="profile-info-box">
                          <Box className="profile-box-white">
                            <Box className="">
                              <Typography className="fw-bold">Company Info</Typography>
                            </Box>
                            <Box className="profile-picture-data">
                              <Accordion className="companyAcc">
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="industry-content"
                                  id="industry-header"
                                >
                                  <Typography className="fw-bold" >Industry *</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box>
                                  <this.CheckboxGroup
                                    options={[
                                      'Advertising',
                                      'Media',
                                      'Entertainment',
                                      'Financial Services',
                                      'Healthcare & Life Sciences',
                                      'Manufacturing',
                                      'Public Sector',
                                      'Retail & Consumer Goods',
                                      'Technology',
                                      'Telecom',
                                    ]}
                                    onChange={this.handleIndustryChange}
                                    
                                  />
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </Box>

                            <Box className="profile-picture-data">
                              <Accordion className="companyAcc">
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="departments-content"
                                  id="departments-header"
                                >
                                  <Typography className="fw-bold">Departments *</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box>
                                  <this.CheckboxGroup options={['Marketing', 'Product Development', 'Finance', "IT", "Operations", "Customer Care/Experience"]}
                                     onChange={this.handleDepartmentChange}
                                   />
                                      </Box>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          </Box>
                          <Button onClick={this.technologyDomainCompanyInfo} className="PresenceFormBtn">
                            Save
                          </Button>
                        </Box>
                      }
                      {
                        this.state.Technology_Stream === true &&
                        <Box>
                          <Typography className="fw-bold">Technology Stream</Typography>
                          <Box className="profile-info-box">
                            <Box className="profile-box-white">
                              <Accordion className="companyAcc">
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="supported-deployment-content"
                                  id="supported-deployment-header"
                                >
                                  <Typography className="fw-bold">Supported Deployment *</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <this.CheckboxGroup
                                    options={[
                                      'Public Cloud',
                                      'Private Cloud/Data Center',
                                      'Hybrid-Deployment',
                                      'On Premises',
                                      'Big Data',
                                    ]}
                                    onChange={this.handleSupportedChange}
                                  />
                                </AccordionDetails>
                              </Accordion>
                              <Accordion className="companyAcc">
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="domain-technologies-content"
                                  id="domain-technologies-header"
                                >
                                  <Typography className="fw-bold">Domain Technologies *</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <this.CheckboxGroup
                                    options={[
                                      '2G Technology',
                                      '3G Technology',
                                      '4G Technology',
                                      '5G Technology',
                                      'Fixed-Line',
                                      'Media/Streaming',
                                      'Enterprise IP',
                                      'IOT',
                                      'Big Data',
                                    ]}
                                    onChange={this.handleDomainTechChange}
                                  />
                                </AccordionDetails>
                              </Accordion>
                              <Accordion className="companyAcc">
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="domain-services-content"
                                  id="domain-services-header"
                                >
                                  <Typography className="fw-bold">Domain Services *</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <this.CheckboxGroup
                                    options={[
                                      'Voice VOLTE',
                                      'Voice SRVCC',
                                      'Voice CSFB',
                                      'Voice NR',
                                      'VOIP',
                                      'Mobile Data',
                                      'Enterprise IP',
                                      'IOT Service',
                                      'Enterprise and Industry-Specific service',
                                    ]}
                                    onChange={this.handleDomainServiceChange}
                                  />
                                </AccordionDetails>
                              </Accordion>
                              <Accordion className="companyAcc">
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="delivery-platform-content"
                                  id="delivery-platform-header"
                                >
                                  <Typography className="fw-bold">Delivery Platform *</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  < this.CheckboxGroup
                                    options={[
                                      'Operating System',
                                      'Management System',
                                      'Data Platform',
                                      'ENGEEM Platform',
                                    ]}
                                    onChange={this.handleDeliveryPlatChange}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          </Box>
                          <Button 
                           onClick={this.updateTechnologyStream} 
                          className="PresenceFormBtn">
                            Save
                          </Button>
                        </Box>
                      }

                      {this.state.DesignClose === true && (
                        <div>
                          <div className="PresenceForm">
                            <div className="DesignSizing-title">
                              Design and Sizing Information
                            </div>
                            <Box className="inputouter">
                              <Box className="domainouter">

                                <Box>
                                  <InputLabel className="input-lable">Deployment Point of Presence (PoP) *</InputLabel>
                                </Box>
                                <Box data-testId="">
                                  <TextField
                                    className="Domain_TextField2"
                                    placeholder=" 20"
                                    data-testId="setPassword1"
                                    value={this.state.deployment_presence} 
                                    onChange={(e) => this.setState({deployment_presence:e.target.value})}
                                  />
                                </Box>
                              </Box>

                              <Box className="domainouter">
                                <Box>
                                  <InputLabel className="input-lable"> Data Stream per PoP *</InputLabel>
                                </Box>
                                <Box data-testId="">
                                  <TextField
                                    placeholder=" 250 Gbps"
                                    data-testId="setPassword2"
                                    className="Domain_TextField2"
                                    value={this.state.data_stream} 
                                    onChange={(e) => this.setState({data_stream:e.target.value})}
                                  />
                                </Box>
                              </Box>
                              <Box className="domainouter">
                                <Box>
                                  <InputLabel className="input-lable">Concurrent Users *</InputLabel>
                                </Box>
                                <Box data-testId="input-lable">
                                  <TextField
                                    className="Domain_TextField2"
                                    placeholder="50-100"
                                    data-testId="setPassword3"
                                    value={this.state.concurrent_users}
                                    onChange={(e) => this.setState({concurrent_users:e.target.value})}

                                  />
                                </Box>
                              </Box>
                              <Box className="domainouter">
                                <Box>
                                  <InputLabel className="input-lable">Metrics Retention *</InputLabel>
                                </Box>
                                <Box data-testId="">
                                  <TextField
                                    placeholder=" 3 Months"
                                    data-testId="setPassword4"
                                    className="Domain_TextField2"
                                    value={this.state.data_retention}
                                    onChange={(e) => this.setState({data_retention:e.target.value})}
                                  />
                                </Box>
                              </Box>
                              <Box className="domainouter">
                                <Box>
                                  <InputLabel className="input-lable">Data Retention *</InputLabel>
                                </Box>
                                <Box data-testId="">
                                  <TextField
                                    placeholder=" 1 Month"
                                    data-testId="setPassword5"
                                    className="Domain_TextField2"
                                    value={this.state.data_retention}
                                    onChange={(e) => this.setState({data_retention:e.target.value})} />
                                </Box>
                              </Box>
                              <Box className="domainouter">
                                <Box>
                                  <InputLabel className="input-lable">Raw Data Retention *</InputLabel>
                                </Box>
                                <Box data-testId="input-lable">
                                  <TextField
                                    placeholder=" 2 Months"
                                    data-testId="setPassword6"
                                    className="Domain_TextField2"
                                    value={this.state.raw_data_retention}
                                    onChange={(e) => this.setState({raw_data_retention:e.target.value})} />

                               

                                </Box>
                              </Box>
                            </Box>

                          </div>
                          <Button onClick={this.updateDesignAndSizing} className="PresenceFormBtn">
                            Save
                          </Button>
                        </div>

                      )}
                      {
                        this.state.Domain_Technology === true &&
                        <Box>
                          <Box className="profile-info-box">
                            <Box className="profile-box-white">
                              <Box className="profile-picture-data">
                                <Typography className="fw-bold">Domain Technology Profile</Typography>
                              </Box>

                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="ran-providers-content"
                                  id="ran-providers-header"
                                >
                                  <Typography className="fw-bold">RAN Providers</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <this.CheckboxGroup options={['Multi Selection list', 'Multi Selection list', 'Multi Selection list']}
                                  onChange={this.handleRanProviderChange} />
                                </AccordionDetails>
                              </Accordion>

                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="core-providers-content"
                                  id="core-providers-header"
                                >
                                  <Typography className="fw-bold">CORE Providers</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <this.CheckboxGroup options={['Multi Selection list', 'Multi Selection list', 'Multi Selection list']} 
                                   onChange={this.handleCoreProviderChange}/>
                                </AccordionDetails>
                              </Accordion>

                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="ip-networks-providers-content"
                                  id="ip-networks-providers-header"
                                >
                                  <Typography className="fw-bold" >IP Networks Providers</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <this.CheckboxGroup options={['Multi Selection list', 'Multi Selection list', 'Multi Selection list']} 
                                   onChange={this.handleIpProviderChange}/>
                                </AccordionDetails>
                              </Accordion>

                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="oss-providers-content"
                                  id="oss-providers-header"
                                >
                                  <Typography className="fw-bold">Operating Systems (OSS) Providers</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <this.CheckboxGroup options={['Multi Selection list', 'Multi Selection list', 'Multi Selection list']}
                                   onChange={this.handleOssProviderChange} />
                                </AccordionDetails>
                              </Accordion>

                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="management-monitoring-providers-content"
                                  id="management-monitoring-providers-header"
                                >
                                  <Typography className="fw-bold">Management and Monitoring Providers</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <this.CheckboxGroup options={['Multi Selection list', 'Multi Selection list', 'Multi Selection list']}
                                  onChange={this.handleMonitoringProviderChange} />
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          </Box>
                          <Button 
                          onClick={this.updateDomainTechnologyProfile} 
                          className="PresenceFormBtn">
                            Save
                          </Button>
                        </Box>
                      }
                      {
                        this.state.domain_profileClose === true &&
                        <Box>
                          <Box>
                            <Box className="edit-btn companyInfo-wrapper">
                              <Typography className="companyName">Company Info</Typography>

                              <Button
                                className="editBTN"
                                onClick={this.Company_Info_changed}
                              >
                                {this.state.Company_Info ? "Cancel" : "Edit"}
                              </Button>
                            </Box>
                            <Box className="profile-info-box">
                              <Box className="profile-box-white">
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>                                    <Grid item xs={2}>
                                    <label>Industry</label>
                                  </Grid>
                                    <Grid item xs={10}>
                                      <div className="data-right">
                                        Advertising, Media, and Technology
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>                                    <Grid item xs={2}>
                                    <label>Departments</label>
                                  </Grid>
                                    <Grid item xs={10}>
                                      <div className="data-right">
                                        Marketing, IT, Operations, and Product
                                        Development
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Box className="edit-btn companyInfo-wrapper">
                              <Typography className="companyName">Technology</Typography>

                              <Button
                                className="editBTN"
                                onClick={this.Technology_Stream_Changed}
                              >
                                {this.state.Technology_Stream ? "Cancel" : "Edit"}
                              </Button>
                            </Box>
                            <Box className="profile-info-box">
                              <Box className="profile-box-white">
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>                                    <Grid item xs={3}>
                                    <label>Supported Deployment</label>
                                  </Grid>
                                    <Grid item xs={9}>
                                      <div className="data-right">
                                        Public
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>                                    <Grid item xs={3}>
                                    <label>Domain Technologies</label>
                                  </Grid>
                                    <Grid item xs={9}>
                                      <div className="data-right">
                                        2G Technology                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>                                    <Grid item xs={3}>
                                    <label>Services</label>
                                  </Grid>
                                    <Grid item xs={9}>
                                      <div className="data-right">
                                        Voice VOLTE                                    </div>
                                    </Grid>
                                  </Grid>


                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>                                    <Grid item xs={3}>
                                    <label>Delivery Platform</label>
                                  </Grid>
                                    <Grid item xs={9}>
                                      <div className="data-right">
                                        Operating System
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Box className="edit-btn companyInfo-wrapper">
                              <Typography className="companyName">Design and Sizing Information</Typography>
                              <Button
                                className="editBTN"
                                onClick={this.Design_and_Sizing_Changed}
                              >
                                {this.state.DesignClose ? "Cancel" : "Edit"}
                              </Button>
                            </Box>

                            <Box className="profile-info-box">
                              <Box className="profile-box-white">
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>                                    <Grid item xs={4}>
                                    <label>Deployment Point of Presence (POP)</label>
                                  </Grid>
                                    <Grid item xs={8}>
                                      <div className="data-right">
                                        20
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>

                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={4}>
                                      <label>Data Stream per POP </label>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="data-right">
                                        250 Gbps
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={4}>
                                      <label>Concurrent Users</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="data-right">
                                        50-100
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={4}>
                                      <label>Metrics Retention</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="data-right">
                                        3 Month
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={4}>
                                      <label>Data Retention</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="data-right">
                                        1 Month
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={4}>
                                      <label>Raw Data Retention</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="data-right">
                                        2 Months
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Box className="edit-btn companyInfo-wrapper">
                              <Typography className="companyName">Domain Technology Profile</Typography>
                              <Button
                                className="editBTN"
                                onClick={this.Domain_Technology_Changed}
                              >
                                {this.state.Domain_Technology ? "Cancel" : "Edit"}
                              </Button>
                            </Box>
                            <Box className="profile-info-box">
                              <Box className="profile-box-white">
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={2}>
                                      <label>RAN Providers</label>
                                    </Grid>
                                    <Grid item xs={10}>
                                      <div className="data-right">
                                        List
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={2}>
                                      <label>CORE Providers </label>
                                    </Grid>
                                    <Grid item xs={10}>
                                      <div className="data-right">
                                        List
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={4}>
                                      <label>IP Network Providers</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="data-right">
                                        List
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={4}>
                                      <label>Metrics Retention</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="data-right">
                                        List
                                      </div>
                                    </Grid>
                                  </Grid>


                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={4}>
                                      <label>Operating System (OSS) Providers</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="data-right">
                                        List
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                                <Box className="profile-picture-data">
                                  <Grid container spacing={0} style={{ alignItems: 'center', }}>
                                    <Grid item xs={4}>
                                      <label> Management and Monitoring Providers</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <div className="data-right">
                                        List
                                      </div>
                                    </Grid>
                                  </Grid>

                                </Box>
                              </Box>
                            </Box>

                          </Box>
                        </Box>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

