import React from "react";


// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Divider,
    Container,
    Card,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Shape, Twitter, youtube, Google, Facebook } from "../../email-account-login/src/assets";
import { Frame12, Frame78, Frame11, Frame22, Frame66, image_logo, card, computer, service, image07, imageRound1, imageRound2, imageRound3, imageRound4, imageRound5, imageRound6, Image_logo1, image01, image02, image03, image08 } from "./assets";
import { createTheme } from "@material-ui/core/styles";
// import Carousel from "react-material-ui-carousel"
// Customizable Area End

import LandingPageController, {
    Props,
} from "./LandingPageController";


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },

});
// Customizable Area End


export default class LandingPageWeb extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {

        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "80%", margin: "auto" }}>
                    <Box>
                        <Box className="mainBox">
                            <Box style={{ marginLeft: "5%", width: "auto" }}>
                                <Box style={{ color: "or", fontSize: "36px", width: "auto" }}>
                                    <img className="rootboximageLogo" style={{ width: "100%", marginLeft: "-75%", paddingTop: "3%", display: "flex" }} src={Image_logo1}></img>
                                </Box>


                            </Box>
                            <Box style={{ display: "flex", gap: "10%",fontFamily:"Roboto" }}>
                                <Box>
                                    <Button className="rootboxbutton" onClick={() => this.handleloginRedirect()} data-testId="loginpage" style={{ color: "rgb(19, 145, 171)",fontWeight:700, fontSize:"24px", borderRadius: "8%", border: "2px solid rgb(19, 145, 171)", width: "8vw", textTransform: "inherit", marginRight: "-100px", letterSpacing: "0.01857em" }}>
                                        Log in
                                    </Button>
                                </Box>
                                <Box>
                                    <Button className="rootboxsignupbtn"  data-testId="signupPage" onClick={() => this.handleSignUpRedirect()} style={{ background: "rgba(19, 145, 171, 1)",fontSize:"24px", color: "white", 
                                    borderRadius: "8%", border: "2px solid rgb(19, 145, 171)", width: "8vw", fontWeight:700, textTransform: "inherit", marginLeft: "100px", letterSpacing: "-0.01em" }}>
                                        Sign  up
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                
                <Box style={{ backgroundImage: `url(${Frame78})`, display: "flex", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                    <Box className="rootbox1">
                        <Box className="rootbox001" style={{ paddingTop: "5%" }}>
                            <Typography style={{
                                fontWeight: 400,lineHeight: "28px",fontSize: "24px",
                                textTransform: "uppercase",}}>
                                Attention IP and Network Operators who want a modern network management system</Typography>
                            <Typography style={{
                                fontWeight: 700, fontSize: "37px", color:"#232638", lineHeight:"60px", width:"104%", marginTop:"2%"
                            }} className="rootbox3">How can operators run and
                             orchestrate autonomous network management functionalities across 
                            multi-domains and operation systems Without fragmented deployment architecture,inconsistent data management, 
                            and costly manpower in less than 10 days Guaranteed!</Typography>
                            <Box className="rootbox004">
                                <Typography style={{ fontWeight: 400, lineHeight:" 36px", fontSize:" 24px", width: "44%",
                                marginTop:"2%" , letterSpacing:" 0.02938"}} className="rootbox4">
                                    Claim Your FREE 1-Month Trial to Discover the Step-By-Step Blueprint To Easily Design
                                     long-term technology architecture and management automation functions (Valued at €65 000)</Typography>
                            </Box>
                            <Box style={{ color: "white", height: "25px" }}>/</Box>
                            <Box style={{ display: "flex", marginTop: "55px", marginBottom: "5%" }}>
                                <Box style={{ display: "flex" }}>
                                    <Button className="rotboxtrialbtn" data-testId="FreeTrialButton"
                                     onClick={() => this.handleSignUpRedirect()}
                                      style={{ backgroundColor: "#1391AB", textTransform: "inherit", color: "white",
                                      width: "28vw", fontWeight: 700, fontSize:"24px" }}>
                                        Claim Your FREE TRIAL</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box style={{ color: "white", height: "25px", fontFamily:"Roboto" }}>/</Box>
                <Box style={{ backgroundImage: `url(${Frame11})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", backgroundPosition:"center" }}>
                    <Box className="rootbox5">
                        <Box style={{ padding: "5%" }}>
                            <Typography style={{color:"#232638", fontSize:"32px", lineHeight: "48px", fontWeight:700, textAlign:"center", width:"78%", margin:"auto"}} className="rootbox005">Finally ... A Quick and Easy way to End costly and time-consuming network operating improvement plans!</Typography>
                            <Typography style={{ color:"#232638", fontWeight: 400, lineHeight: "36px ",fontSize: "24px", width:"101%", margin:"auto", padding:"2%"}} className="rootbox7">Whatever the golden vendor, products, and 
                                management procedures that have been invested for so long, NetOps teams are rarely aware of what is truly happening to network end-users. Are you sick of that? Tired of product outcomes inefficiency,
                                operational drag on your teams, and costing you money every year? And looking for ways to proactively
                                identify End-to-End network faults, critical service experience impact, and fix them faster?</Typography>
                            <Typography style={{ color:"#232638", fontWeight: 400, lineHeight: "36px ",fontSize: "24px", width:"101%", margin:"auto", padding:"2%"}}  className="rootbox8">At ENGEEM we help IT and Network Operators achieve a Perfect Monitoring and Management system in the shortest time possible using industry leading innovations.</Typography>
                            <Typography style={{ color:"#232638", fontWeight: 700, lineHeight: "28.13px ",fontSize: "24px", padding:"2%"}} className="rootbox9">We did it without years of hard work and trials and errors!</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="rootbox11bg" style={{ backgroundImage: `url(${Frame22})`, backgroundRepeat:"no-repeat", backgroundSize:"100%"}}>
                    <Box className="rootbox10">
                        <Box className="rootbox11" style={{width:"90%"}}>                            
                            <Typography className="rootbox112" style={{color:"#232638", fontSize:"32px", lineHeight: "48px", fontWeight:700, textAlign:"center", width:"104%%", marginTop:"2%", margin:"auto"}}>
                                Here’s A Brief Outline Of What We’ll Cover during Your FREE 1-Month Trial
                            </Typography>
                            {
                                this.state.items.map((item: any) => {
                                    return (
                                        <Box className="rootbox00111">
                                            <Box style={{ height: "20%" }}>
                                                <img className="rootbox00211" src={item.image} />
                                            </Box>
                                            <Box style={{paddingLeft:"3%"}}>
                                                <Typography className="rootbox13" style={{fontSize: "24px", lineHeight:"36px", fontWeight:400}} >{item.description}</Typography>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                           
                            <Box style={{ display: "flex" , padding:"10%"}} >
                                <Button data-testId="newbutton" onClick={() => this.handleSignUpRedirect()}
                                    style={{ backgroundColor: "#1391AB", textTransform: "inherit", color: "white",
                                    width: "30vw", fontWeight: 700, marginLeft: "32px", fontSize:"24px" }} className="root19 rotboxtrialbtn"  >Claim Your FREE TRIAL</Button>
                            </Box>
                        </Box>
                        <Box style={{ display: "flex" }}>
                            <img className="rootbox00101" src={image01} />
                        </Box>
                    </Box>
                </Box>

                <Box className="rootbox15" style={{backgroundImage:`url(${Frame12})`, backgroundSize:"100%", backgroundPosition:"Inherit", backgroundColor:"#F3F3F3"}}>
                    <Box>
                        <Box>
                            <Typography className="rootbox00115" style={{width:"94%" ,display:"block" ,textAlign:"center", color:"#232638", lineHeight:"54px", fontSize:"30px", fontWeight:700, padding:"2%", margin:"auto"
                        }}> 
                             We've produced impressive outcomes for ourselves and our clients, 
                             which we've pre-built for you to use now with a single click of a button</Typography>
                        </Box>
                        <Box className="rootbox18">
                            <Box className="rootbox00118">
                                {this.state.dataList.slice(0, this.state.displayLimit).map((data: any) => (
                                    <Box key={data.name}>
                                        <ul >
                                            <li className="rootboxlist" style={{fontSize:"24px", fontWeight:700, lineHeight:"28.13px"}}>{data.name}</li>
                                        </ul>
                                        <p className="rootboxlistitem" style={{ fontWeight: 400, fontSize:"24px", lineHeight:"36px", paddingLeft:"4%"}} >{data.description}</p>
                                    </Box>
                                ))}

                                {this.state.dataList.length > this.state.displayLimit && (
                                    <p data-testId="viewMore" style={{ color: "#1391AB", fontWeight: 700, cursor: "pointer" }} onClick={this.handleViewMoreClick}>View more</p>
                                )}
                            </Box>
                            <Box className="rootboox00218" style={{}}>
                                <img className="rootbox21" src={image02} />
                                <img className="rootbox20" src={image03} />
                            </Box>
                        </Box>
                        <Box style={{ width: "99%" }}>
                            <p className="rootbox22">Over the last 25 years we’ve shown to dozens of IT and operators how to build the perfect monitoring strategy, manage technology integration efficiently for reducing total expenditure costs without years of hard work and trials and errors; And we can do it for you too!</p>
                        </Box>
                    </Box>
                </Box>

                <Box className="rootbox23">
                    <Box>
                        <Box>
                            <Typography className="rootboxheading" style={{width:"50%",color:"#F3F3F3" , padding:"2%", fontWeight: 700, 
                            textAlign: "center", margin:"auto", fontSize: "32px", lineHeight: "48px" }}>
                            Here’s Another Taste Of What You Can Expect When You Book Your FREE Trial Project</Typography>
                        </Box>
                        <Box style={{width:"70%", margin:"auto"}}>
                            <Grid container spacing={2} >
                                <Grid item lg={6} md={6} xs={12} >
                                    <Card className="rootCard23" style={{ display: "block", background: "#FFFFFF",  width: "88%", height: "536px", padding:"4%", borderRadius: "16px", textTransform: "inherit", textAlign: "start" }}>
                                        <Box className="rootbox24" style={{ width:"96%", height:"96%",background: "#EDF9FC", padding: "3% 1% 1% 3%", borderRadius: "16px" }}>
                                            <img src={imageRound1} className="rootboximage24" style={{ height: "9%", width: "8%" }} />
                                            <Typography className="rootboxpera24"  style={{ fontWeight: 700, letterSpacing: "0.01938em", fontSize: "24px" }}>Cloud-native App and service store platform for automated network assurance for infinite connected services.</Typography>
                                            <Typography className="rootboxpera24" style={{ fontSize: "24px", fontWeight: 400, marginTop: "2%" }}>ENGEEM provides scalable analytics, operational flow, and managed services that give operators and connected industries the ability to easily move to data monetisation, securely create new services, and new revenue streams – only leveraging their existing connectivity resources</Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                                <Grid item lg={6} md={6} xs={12}>
                                    <Card className="rootCard23" style={{ display: "block", background: "#FFFFFF", width: "88%", height: "536px", padding:"4%", borderRadius: "16px", textTransform: "inherit", textAlign: "start" }}>
                                        <Box className="rootbox24" style={{ width:"96%", height:"96%", background: "#EDF9FC",padding: "3% 1% 1% 3%", borderRadius: "16px" }}>                      
                                            <img src={imageRound2} className="rootboximage24"  style={{ height: "9%", width: "8%"}} />
                                            <Typography className="rootboxpera24"  style={{  fontWeight: 700, letterSpacing: "0.01938em", fontSize: "24px"}}>End-to-end programmable network analytics with high impact on enriching device and end-user experience, TCO efficiency, network resource experience and revenue streams creation.</Typography>
                                            <Typography className="rootboxpera24"  style={{ fontSize: "24px", marginTop: "2%" }}>ENGEEM strictly focuses on providing the most insightful of the end-user experience of mobile connection.</Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                                <Grid item lg={6} md={6} xs={12}>
                                    <Card className="rootCard23" style={{ display: "block", background: "#FFFFFF",  width: "88%", height: "536px", padding:"4%", borderRadius: "16px", textTransform: "inherit", textAlign: "start" }}>
                                        <Box className="rootbox24" style={{width:"96%", height:"96%", background: "#EDF9FC", padding: "3% 1% 1% 3%", borderRadius: "16px" }}>  
                                            <img src={imageRound3} className="rootboximage24"  style={{  height: "9%", width: "8%"}} />
                                            <Typography className="rootboxpera24"  style={{ fontWeight: 700,letterSpacing: "0.01938em", fontSize: "24px"}}>Easily develop a end-to-end monitoring applications that remove network complexity barriers</Typography>
                                            <Typography className="rootboxpera24"  style={{ fontWeight: 400, fontSize: "24px", marginTop: "2%" }}>Security, User Experience, Network Resources, Automation, and other Analytics, we provide the full path to digital service operation and innovation, enabling industry to reduce technology skills barriers, meeting service experience and business innovation requirement.</Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                                <Grid item lg={6} md={6} xs={12}>
                                    <Card className="rootCard24" 
                                    style={{ display: "block", background: "#FFFFFF", width: "88%", height: "536px", padding:"4%", borderRadius: "16px", textTransform: "inherit", textAlign: "start" }}>
                                        <Box className="rootbox24" style={{width:"96%", height:"96%", background: "#EDF9FC", padding: "3% 1% 1% 3%", borderRadius: "16px" }}>  
                                            <img src={imageRound4} className="rootboximage24" style={{ height: "9%", width: "8%"}} />
                                            <Typography className="rootboxpera24" style={{ fontWeight: 700,letterSpacing: "0.01938em", fontSize: "24px" }}>Instant network fault control and monitoring.</Typography>
                                            <Typography className="rootboxpera24" style={{ fontWeight: 400,  fontSize: "24px", marginTop: "2%", }}>Why use sophisticated tools or costly human intervention to understand anomalies and root causes when you can instantly identify what your network instabilities are, where they are located, and which customer segments they affect?</Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} xs={12}>
                                    <Card className="rootCard23" style={{ display: "block", justifyContent: "center", background: "#FFFFFF", width: "90%", padding:"4%", height: "361px", borderRadius: "16px", textTransform: "inherit", textAlign: "start" }}>
                                        <Box className="rootbox24" style={{ height: "297px", width: "95%", background: "#EDF9FC",  borderRadius: "16px" , padding: "3% 1% 1% 3%"}}>
                                            <img src={imageRound5} className="rootboximage24" style={{ height: "45px", width: "45px"}} />
                                            <Typography className="rootboxpera25" style={{ fontWeight:700,letterSpacing: "0.01938em", fontSize: "24px" }}>Business Application and Digital ProcessBuild applications and business-critical procedures to swiftly leverage 5G potential.</Typography>
                                            <Typography className="rootboxpera25" style={{ fontWeight: 400,  fontSize: "24px" }}>Businesses and organisations are increasingly seeking for solutions to reduce the time and costs associated with complex application project methodologies, reduce go-to-market limitations, boost agility, and quickly capitalise on the 5G / IoT growth potential.</Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Box className="rootbox30">
                                <Box style={{ display: "flex" }}  >
                                    <Button data-testId="FreeTrialButton1" onClick={() => this.handleSignUpRedirect()} style={{ backgroundColor: "#1391AB", textTransform: "inherit", color: "white", height: "56px", width: "33vw", fontWeight: 700, marginTop: "10px", }}  >Claim Your FREE TRIAL</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="rootbox31">
                    <Container>
                        <Box className="rootbox32">
                            <Box>
                                <Box style={{display:"flex", justifyContent:"center"}}>
                                    <img src={image07} />
                                </Box>
                            
                                <Box>
                                    <Typography className="rootboxrock" style={{ color:"#232638", textAlign: "center", paddingTop: "2%", fontWeight: 700, fontSize: "32px", lineHeight: "48px", width:"46%", margin:"auto" }}>100% Rock-Solid</Typography>
                                    <Typography className="rootboxrock" style={{ color:"#232638", textAlign: "center", fontWeight: 700, fontSize: "32px", lineHeight: "48px", width:"46%",  margin:"auto", paddingBottom:"2%" }}> Value and Success Guarantee!</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography className="rootboxrockpera" style={{ textAlign: "center", marginTop: "2%", fontWeight: 400, fontSize: "24px", lineHeight: "36px", width:"70%", margin:"auto" }}>When you join us at ENGEEM, you can invest with confidence because you’ll always be covered by our Pay-After-Value-Delivery guarantee!</Typography>
                            </Box>
                            <Box>
                                <Typography className="rootboxrockpera" style={{ textAlign: "center", marginTop: "2%", fontWeight: 400, fontSize: "24px", lineHeight: "36px" }}>In the extremely unlikely event you’re not satisfied with our product deliverables or we can’t help you automate a specific part of your network operation after 60 days period,
                                     we will higher our service level for exclusive customisation and personal babysat service free of charge until you
                                     reach the objectives you’ve invested with us – No questions asked. </Typography>
                            </Box>
                        </Box>
                    </Container>
                </Box>
                <Box className="rootbox34" style={{ background: "#EDF9FC" }}>
                    <Box>
                        <p className="rootbox34pera" style={{ textAlign: "center", fontWeight: 700, fontSize: "32px", lineHeight: "2.2em" }}>
                            Here is what you will be delivered with:
                        </p>
                        <p className="rootbox34pera2" style={{ textAlign: "center", marginTop: "1%", fontWeight: 700, fontSize: "24px", lineHeight: "36px" }}>
                            Automate Your Network Monitoring in less than 3 months Without Painful </p>
                        <p className="rootbox34pera2" style={{ textAlign: "center", fontWeight: 700, fontSize: "24px", lineHeight: "36px" }}> Cost and Tools, While Saving you 200 K€ per Year on your IT/NetOps Plan.
                        </p>
                        <p className="rootbox34pera3" style={{ textAlign: "center", color: "#1391AB", fontWeight: 700, fontSize: "32px", lineHeight: "37px" }}>
                            ENGEEM Offer Total Value : 205 000 Eur
                        </p>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Card style={{ display: "block", background: "white", borderRadius: "16px", height: "300px", textTransform: "inherit", textAlign: "start", padding: "5%" }}>
                                        <Box className="rootbox24" style={{ display: "block", borderRadius: "16px", padding: "9% 4% 10% 4%", marginTop: "10%" }}>
                                            <img src={imageRound1} style={{ height: "20%", width: "8%" }} />
                                            <Typography className="rootboxcard25" component={'span'} style={{ fontWeight: 700, fontSize: "24px", display: "flex" }}>3 Months Perfect Network Monitoring Strategy Blueprint ( 32 K€ Value ).</Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Card style={{ display: "block", background: "white", borderRadius: "16px", height: "300px", textTransform: "inherit", textAlign: "start", padding: "5%" }}>
                                        <Box className="rootbox24" style={{ display: "block", borderRadius: "16px", padding: "9% 4% 10% 4%", marginTop: "10%" }}>
                                            <img src={imageRound2} style={{ height: "20%", width: "8%" }} />
                                            <Typography className="rootboxcard25" component={'span'} style={{ fontWeight: 700, fontSize: "24px", display: "flex" }}>ENGEEM - PLATFORM: 6 - Months Enterprise Account license ( 38 K€ Value ).</Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Card style={{ display: "block", background: "white", borderRadius: "16px", height: "300px", textTransform: "inherit", textAlign: "start", padding: "5%" }}>
                                        <Box className="rootbox24" style={{ display: "block", borderRadius: "16px", padding: "9% 4% 10% 4%", marginTop: "10%" }}>
                                            <img src={imageRound3} style={{ height: "20%", width: "8%" }} />
                                            <Typography className="rootboxcard25" component={'span'} style={{ fontWeight: 700, fontSize: "24px", display: "flex" }}> Instant Network Problem Hack with Integrated Anomaly Pattern and Predictive User Experience ( 50 K€ Value ).</Typography>
                                        </Box>
                                    </Card>
                                </Grid> <Grid item lg={4} md={6} xs={12}>
                                    <Card style={{ display: "block", background: "white", borderRadius: "16px", height: "300px", textTransform: "inherit", textAlign: "start", padding: "5%" }}>
                                        <Box className="rootbox24" style={{ display: "block", borderRadius: "16px", padding: "9% 4% 10% 4%", marginTop: "10%" }}>
                                            <img src={imageRound4} style={{ height: "20%", width: "8%" }} />
                                            <Typography className="rootboxcard25" component={'span'} style={{ fontWeight: 700, fontSize: "24px", display: "flex" }}>Automated Performance Routine Step-by-Step Automation Guideline and Templates ( 25 K€ Value ).</Typography>
                                        </Box>
                                    </Card>
                                </Grid> <Grid item lg={4} md={6} xs={12}>
                                    <Card style={{ display: "block", background: "white", borderRadius: "16px", height: "300px", textTransform: "inherit", textAlign: "start", padding: "5%" }}>
                                        <Box className="rootbox24" style={{ display: "block", borderRadius: "16px", padding: "9% 4% 10% 4%", marginTop: "10%" }}>
                                            <img src={imageRound5} style={{ height: "20%", width: "8%" }} />
                                            <Typography className="rootboxcard25" component={'span'} style={{ fontWeight: 700, fontSize: "24px", display: "flex" }}>Fully Extensible and Customisable Big Data Analytics Base to go beyond network monitoring ( 42 K€ Value ).</Typography>
                                        </Box>
                                    </Card>
                                </Grid> <Grid item lg={4} md={6} xs={12}>
                                    <Card style={{ display: "block", background: "white", borderRadius: "16px", height: "300px", textTransform: "inherit", textAlign: "start", padding: "5%" }}>
                                        <Box className="rootbox24" style={{ display: "block", borderRadius: "16px", padding: "9% 4% 10% 4%", marginTop: "10%" }}>
                                            <img src={imageRound6} style={{ height: "20%", width: "8%" }} />
                                            <Typography className="rootboxcard25" component={'span'} style={{ fontWeight: 700, fontSize: "24px", display: "flex" }}>Unlimited Metrics and Pre-Integrated Operational Analytics ( 18 K€ Value ).</Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                <Box style={{ backgroundColor: "#F3F3F3", width: "100%", margin: "auto" }}>
                    <img className="rootboximage" src={image08} style={{ width: "10%", height: "auto" }}></img>
                    <Box>
                        <Typography style={{ textAlign: "center", fontWeight: 700, fontSize: "2em"}} className="rootbox17">How much you pay now ?</Typography>
                        <Typography className="rootbox17" style={{ textAlign: "center", color: "#1391AB", fontWeight: 700, marginTop: "1%", fontSize: "2em" }}>Get Operational Tips and Strategies for effective</Typography>
                        <Typography className="rootbox17" style={{ textAlign: "center", color: "#1391AB", fontWeight: 700, marginTop: "5px", fontSize: "2em" }}>Management System for only 25 000 Eur.</Typography>
                        <Typography className="rootbox17" style={{ textAlign: "center", marginTop: "2%", fontWeight: 700, marginBottom: "40px", fontSize: "1.5em" }}> The price include the :</Typography>
                    </Box>
                    <Grid container spacing={2} style={{ padding: "3%" }} >
                        <Grid item lg={4} md={6} xs={12} >
                            <Card style={{ display: "block", background: "linear-gradient(144.7deg, rgba(19, 145, 171, 0.5) 7.25%, #EDF9FC 100.6%)", borderRadius: "2%", width: "100%", height: "373px", textTransform: "inherit" }}>
                                <Box>
                                    <img src={card} style={{ height: "20%", width: "20%", marginLeft: "10%" }} />
                                    <p style={{ fontWeight: 700, marginLeft: "4%", marginTop: "5%", fontSize: '20px', lineHeight: "2.2em" , color:"#232638"}}  >Zero Software fees</p>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                            <Card style={{ display: "block", background: "linear-gradient(144.7deg, rgba(19, 145, 171, 0.5) 7.25%, #EDF9FC 100.6%)", borderRadius: "2%", width: "100%", height: "373px", textTransform: "inherit", }}>
                                <Box >
                                    <img src={service} style={{ height: "20%", width: "20%", marginLeft: "10%" }} />
                                    <p style={{ fontWeight: 700, marginLeft: "4%", marginTop: "5%", fontSize: '20px', lineHeight: "2.2em", color:"#232638" }} className="rootbox16">Dedicated expert team for build and service adjustment.</p>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item lg={4} md={12} xs={12}>
                            <Card style={{ display: "block", background: "linear-gradient(144.7deg, rgba(19, 145, 171, 0.5) 7.25%, #EDF9FC 100.6%)", borderRadius: "2%", width: "100%", height: "373px", textTransform: "inherit" }}>
                                <Box>
                                    <img src={computer} style={{ height: "20%", width: "20%", marginLeft: "10%", marginTop:"2%" }} />
                                    <p style={{ fontWeight: 700, marginLeft: "4%", marginTop: "5%", fontSize: '20px', lineHeight: "2.2em", color:"#232638" }} >100% project resources access with strategy blueprints, discoveries, and intelligence for effective network management transformation.</p>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>

                <Box style={{ backgroundColor: "#F3F3F3", width: "100%", margin: "auto", paddingBottom: "4%" }}>
                    <Box style={{ backgroundColor: "#1AC2C233", height: "610px", marginTop: "3%", width:"90%", margin: "auto"}}>
                        <Box style={{ paddingTop: "2%" }}>
                            <Box style={{width:"70%", margin:"auto"}}>
                                <Typography className="rootbox36" style={{ textAlign: "center", fontWeight: 700, color: "#1391AB", fontSize: "36px", margin: "2%", lineHeight: "42px" }} >Sign up today for the Quickest Start!</Typography>
                                <Typography className="rootbox35" style={{ textAlign: "center", fontWeight: 700, fontSize: "24px", marginTop: "1%", lineHeight: "36px", width:"110%", margin:"auto"}}>Get Started now and we'll put you in our next group, which begins on @Monday; otherwise, you'll have to wait until our next kickoff date.</Typography>
                                <Typography className="rootbox35" style={{ textAlign: "center", fontWeight: 400, fontSize: "24px", lineHeight: "36px", width:"96%", margin:"auto" }}> Because we are so meticulous about the quality of our deliverables and the client delivery technique, we are limited to handling 20 new projects this month to ensure the greatest possible one-on-one success! If we are sold out, please leave your email address in order to be notified of any updates or future open sessions! You will be added to our elite waiting list ...The next session begins on October 10th.</Typography>
                                <Typography className="rootbox35" style={{ textAlign: "center", marginTop: "2%", fontWeight: 400, fontSize: "24px", lineHeight: "28px" }}>+ Project Build Capacity countdown in %ENGEEM Offer Total Value : 205 000</Typography>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "2%" }}>
                                <Button data-testId="loginpage1" onClick={() => this.handleloginRedirect()} style={{ backgroundColor: "#1391AB", color: "white", width: "586px", height:"56px", fontSize:"18px", lineHeight:"24px", padding:"16px", fontWeight: 700}}>LOGIN NOW AND GET YOUR PROJECT KICKED-OFF</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                
                <Box style={{ backgroundImage: `url(${Frame66})`, backgroundSize: 'cover', backgroundPosition: 'center', marginTop: "5rem", backgroundRepeat: 'no-repeat', width: "100%", margin: "auto" }}>
                    <img src={image_logo} style={{ marginLeft: "4%", width: "30%" }} />
                    <p style={{ fontWeight: 700, color: "white", marginLeft: "4%", fontSize: "32px" }}>
                        Contact Us
                    </p>
                    <p className="rootboxcontact" style={{ color: "white", marginLeft: "4%", width: "40%", fontSize: "24px" }}>
                        Your feedback matters. contact us with your suggestions, comments, or inquiries, and we’ll be in touch.
                    </p>
                    <Box style={{ marginTop: "32px", marginBottom: "2%", marginLeft: "2%" }}>
                        <Box>
                            <p className="rootboxcontact" style={{ color: "white", fontSize: "24px", marginLeft: "2%" }}>Email Address : customerexperience@engeem.com</p>
                            <p className="rootboxcontact" style={{ color: "white", fontSize: "24px", marginLeft: "2%" }}>Phone Number : (33) 00 11 11 22 19</p>
                        </Box>
                        <Box>
                            <Divider style={{ background: "#F3F3F3", marginTop: "2%", width: "90%" }} />
                        </Box>
                        <Box style={{ marginTop: "2%" }}>
                            <Box className="imageshocialMedia">
                                <img style={{ height: "25px", marginTop: "5px" }} src={Shape}></img>
                                <img style={{ marginLeft: "7px", height: "25px", marginTop: "5px", width: "30px" }} src={youtube}></img>
                                <img src={Google}></img>
                                <img src={Facebook}></img>
                                <img src={Twitter}></img>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start


