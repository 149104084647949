import React from "react";

import {
  Container,
  Box,
  Input,
  TextField,
  Checkbox,
  Button,
  InputLabel,
  Typography,
  Divider,
  Grid,
  CircularProgress,
  
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Select from "react-select";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { image_logo, closeIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CfcustomisableworkflowbyusersController, {
  Props,
  configJSON,
  CountryProps
  
} from "./CfcustomisableworkflowbyusersController";

export default class Cfcustomisableworkflowbyusers extends CfcustomisableworkflowbyusersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {!this.state.isLoader ?
          <Box>
            <Box style={{ background: "linear-gradient(#232638 8.67%, #1391AB 100%)", width: "100%", height: "140px" }}>
              <Box>
                <img
                  src={image_logo}
                  style={{
                    height: "95px",
                    width: "336px",
                    padding: "1%"
                  }} />
              </Box>
            </Box>
            <Box className="scrollbar-container1 customUserProfile" style={{ overflowY: "scroll" }}>
              <Box className="TopFormcontent" >
                <Box className="customUserProfile-inner-wrapper">
                  <Typography className="NotificationTop customUserProfile-title">To finish your account creation, enter the necessary details. </Typography>
                  <Box className="topContainer">

                    <Box>
                      <Grid container spacing={2}>


                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <InputLabel className="w-100 lableStyle">First name*
                            </InputLabel>
                          </Box>
                          <Box data-testId="" >
                            <TextField placeholder=" First name" data-testId="txtFirstName" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })}
                              className="customfileformdateinput">
                            </TextField>
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <InputLabel className="w-100 lableStyle">Last name*
                            </InputLabel>
                          </Box>
                          <Box data-testId="">
                            <TextField placeholder=" Last name" data-testId="txtLastName" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })}
                              className="customfileformdateinput">
                            </TextField>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box >
                            <InputLabel className="w-100 lableStyle">Email address*
                            </InputLabel>
                          </Box>
                          <Box data-testId="">
                            <TextField data-testid="txtEmail" placeholder=" Email address" value={this.state.storeEmail} 
                              className="customfileformdateinput">
                            </TextField>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box>
                            <InputLabel className="w-100 lableStyle">Company*
                            </InputLabel>
                          </Box>
                          <Box data-testId="">
                            <TextField placeholder=" Company" data-testId="txtCompany" value={this.state.company} onChange={(e) => this.setState({ company: e.target.value })}
                              className="customfileformdateinput">
                            </TextField>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="">
                            <InputLabel className="w-100 lableStyle">Phone number*
                            </InputLabel>
                          </Box>
                          <Box data-testId="">
                            <TextField
                              type="number"
                              placeholder=" Phone number" data-testId="txtPhone" value={this.state.phoneNumber} onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                              className="customfileformdateinput">
                            </TextField>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel className="w-100 lableStyle">Country*
                          </InputLabel>
                          <Select
                            data-testId="mySelect"
                            className="customfileformdateinput customfileSelect"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                marginTop: '0px !important',
                              }),
                              valueContainer: (baseStyles) => ({
                                ...baseStyles,
                                height: '56px',
                                borderRadius: '8px',
                                paddingLeft: '16px',
                              }),
                              input: (baseStyles) => ({
                                ...baseStyles,
                                height: '45px',
                                marginTop: '-20px',
                                '& input': {
                                  paddingTop: '13px !important',
                                  paddingLeft: '0px !important',
                                }
                              }),
                            }}
                            options={this.state.countryList.map((countryName) => ({
                              value: countryName,
                              label: countryName,
                            }))}
                            value={{ value: this.state.country, label: this.state.country }}
                            onChange={(selectedOption) => {
                              const selectedCountryName = selectedOption?.value;
                              this.setState({ country: selectedCountryName });
                            }}
                            
                          >
                          </Select>
                        </Grid>

                      </Grid>
                      <Box className="flexNotifucation">
                        <Grid container spacing={2}>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <InputLabel className="lableStyle notification-text">Notification*
                            </InputLabel>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider style={{ color: "#CBD5E1", width: "100%" }} />
                      <Box className="checkBoxRoot1">
                        <Grid container spacing={2}>

                          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              data-testId="myCheckbox"
                              style={{ color: "#1391AB" }} value={this.state.isAcceptNotification} onChange={(e) => this.setState({ isAcceptNotification: !this.state.isAcceptNotification })} />
                            <Typography className="typographyRoot">I accept to receive email notifications for Order and Project Management purposes.</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="centeredFlexContainer">
                        {this.state.errorMessage.length > 0 && <Box
                          className="flexContainerWithBoxShadow" onClick={() => this.setState({ errorMessage: '' })}>
                          <img src={closeIcon} />
                          <Typography style={{ whiteSpace: 'nowrap' }}>{this.state.errorMessage}</Typography>
                        </Box>}
                        <Box>
                          <Button data-testId="handlesubmitbtn" onClick={() => this.handleSubmitUserDetails()}
                            className="cutstome-save-btn"
                            style={{
                              background: this.allFieldsFilled() && this.state?.isAcceptNotification ? "#1391AB" : "#A8B1B3",
                              color: "#232638",
                              width: "135px",
                            }}
                          >Save</Button>
                        </Box>
                      </Box>

                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box> : <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }} ><CircularProgress color="inherit" /></div>}
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End



