//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {ValueType}from "react-select";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
const countryList = [
  { value: 'India', label: 'India' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Iceland', label: 'Iceland'},
  { value: 'Jersey', label: 'Jersey' },
]

export interface CountryProps {
  value: string;
  label:string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  anchor: boolean;
  mainTabValue: number;
  subTabValue: number;
  nestedTabValue: number;
  country: ValueType<any>;
  UserInfoFomrsClose: boolean;
  UserForms: boolean;
  Company_Info: boolean;
  domain_profileClose: boolean;
  Technology_Stream: boolean;
  DesignClose: boolean,
  Domain_Technology: boolean,
  options: string[];
  firstName: string;
  lastName: string;
  email: string;
  storeEmail:string;
  company: string;
  phoneNumber: string;
  // country: string;
  countrySec: CountryProps,
  photo: string;
  isAcceptNotification:boolean,
  userInfomation: string;
  user_firstName: string;
  user_lastName: string;
  user_email: string;
  user_company: string;
  user_phoneNumber: string;
  user_country: string;
  user_photo: string;
  isNestedOpenBar:boolean;
  errorMessage:string;
  token:string;
  images: string[];
  isModalOpen:boolean;
  selectedImageIndex: number,
  countryList:CountryProps[],
  user_isAcceptNotification:boolean;
  isFormFilled:boolean;
  imageSrc: string,
  fileTypes:string[],
  uploadImage:boolean;
  base64Image:string;
  storeImage:string;
  imageData:string;
  isLoader:boolean;

  // Customizable Area Start
  deployment_presence:string;
  data_stream:string;
  concurrent_users:string;
  metrics_retention:string;
  data_retention:string;
  raw_data_retention:string;
  selectedIndustry: string[],
  selectedDepartment: string[],
  //Save2
  selectedsuportedDeploy : string[],
  selectedDomainTech : string[],
  selectedDomainService : string[],
  selectedPlatform : string[],
  //Save4
  selectedRanProviders: string[],
  selectedCoreProviders: string[],
  selectedIpNetworkProviders: string[],
  selectedOsProviders: string[],
  selectedMonitoringProviders: string[]
  // Customizable Area End
}

interface SS {
  id: any;

  // Customizable Area Start
  // Customizable Area End
}

export default class CfcustomisableworkflowbyusersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  profileApiCallId: string = "";
  getProfileApiCallId: string = "";
  postProfileApiCalled:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
           // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      anchor: false,
      mainTabValue: 0,
      subTabValue: 0,
      nestedTabValue: 0,
      UserInfoFomrsClose: true,
      domain_profileClose: true,
      UserForms: false,
      Company_Info: false,
      Technology_Stream: false,
      DesignClose: false,
      Domain_Technology: false,
      options: [],
      firstName: "",
      lastName: "",
      email: "",
      storeEmail:"",
      company: "",
      phoneNumber: "",
      country: null,
      photo: "",
      isAcceptNotification:false,
      userInfomation: "",
      user_firstName: "",
      user_lastName: "",
      user_email: "",
      user_company: "",
      user_phoneNumber: "",
      user_country: "",
      user_photo: "",
      isNestedOpenBar: "",
      errorMessage:"",
      token:'',
      images:[],
      isModalOpen: false,
      selectedImageIndex: null,
      countryList:countryList,
      user_isAcceptNotification:false,
      isFormFilled: false,
      imageSrc: null,
      fileTypes : ["JPG", "PNG", "GIF"],
      uploadImage:false,
      base64Image: null,
      storeImage:null,
      imageData:'',
      isLoader:true,

      // Customizable Area Start
      deployment_presence:"",
      data_stream:"",
      concurrent_users:"",
      metrics_retention:"",
      data_retention:"",
      raw_data_retention:"",
      selectedIndustry: [],
      selectedDepartment: [],
      //Save2
      selectedsuportedDeploy : [],
      selectedDomainTech :[],
      selectedDomainService : [],
      selectedPlatform : [],
      //Save3
      selectedRanProviders: [],
      selectedCoreProviders: [],
      selectedIpNetworkProviders: [],
      selectedOsProviders: [],
      selectedMonitoringProviders: []
      
  


      // Customizable Area End
    };



    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area Start

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
       if (apiRequestCallId === this.getProfileApiCallId) {
        if(responseJson.data){
          let userDetails = responseJson.data.attributes
          this.setState({ userInfomation: userDetails, user_firstName: userDetails?.first_name, user_lastName: userDetails?.last_name, user_email: userDetails?.email, user_company: userDetails?.company, user_phoneNumber: userDetails?.phone_number , user_country:userDetails?.country, user_isAcceptNotification:userDetails?.notification,storeImage:userDetails.photo , isLoader:false})
            this.props.navigation.navigate("GeneralInformation")
        }else{
            this.setState({isLoader:false})
        }
      }
      if(apiRequestCallId === this.getCountriesApiCallId){
        this.countryApiRequest(responseJson)
      }
     
      else if(apiRequestCallId===this.postProfileApiCalled){
       this.postApiRequest(responseJson)
      }
    }


    // Customizable Area End
  }

    txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };
  
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };
  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };
  
  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  // Customizable Area Start

  componentDidMount(): Promise<void> {
    const storeToken: string = localStorage.getItem('token')
    const localEmail : string = localStorage.getItem('email')
    const parseToken = JSON.parse(storeToken)
    this.setState({ token: parseToken , storeEmail:localEmail })
    setTimeout(()=>{
      this.getProfileInformation()
    },5000)
    this.getCountriesApi()
  }
  handleOpen = () => {
    this.setState({ anchor: true })
  }

  countryApiRequest = (responseJson) => {
    const countryList = responseJson.countries.map((country) => country.name);
    this.setState({ countryList : countryList });
  }
  postApiRequest = (responseJson) => {
    if(responseJson.data){
      this.setState({ UserInfoFomrsClose:true , UserForms:false})
      this.getProfileInformation()
      this.props.navigation.navigate("GeneralInformation")
    }else{
      this.setState({errorMessage:responseJson.errors})
    }
  }
 
  
  allFieldsFilled() {
    const {firstName,lastName,company,phoneNumber,country} = this.state;
    return firstName && lastName && company && phoneNumber && country;
 }

 handleIndustryChange = (selectedOption) => {
  this.setState((prevState) => ({
    selectedIndustry: [...prevState.selectedIndustry, selectedOption],
  }));
};

handleDepartmentChange = (selectedOption) => {
  this.setState((prevState) => ({
    selectedDepartment: [...prevState.selectedDepartment, selectedOption],
  }));
};

handleSupportedChange = (selectedOption) =>{
  this.setState((prevState) => ({
    selectedsuportedDeploy : [...prevState.selectedsuportedDeploy,selectedOption],
  }))
}

handleDomainTechChange = (selectedOption) =>{
  this.setState((prevState) => ({
    selectedDomainTech :[...prevState.selectedDomainTech,selectedOption],
  }))
}
handleDomainServiceChange = (selectedOption) =>{
  this.setState((prevState) => ({
    selectedDomainService : [...prevState.selectedDomainService,selectedOption],
  }))
}

handleDeliveryPlatChange = (selectedOption) =>{
  this.setState((prevState) => ({
    selectedPlatform : [...prevState.selectedPlatform,selectedOption],
  }))
}
handleRanProviderChange = (selectedOption) =>{
  this.setState((prevState) => ({
    selectedRanProviders : [...prevState.selectedRanProviders,selectedOption],
  
  }))
}
handleCoreProviderChange = (selectedOption) =>{
  this.setState((prevState) => ({
    selectedCoreProviders : [...prevState.selectedCoreProviders,selectedOption],

  }))
}
handleIpProviderChange = (selectedOption) =>{
  this.setState((prevState) => ({
    selectedIpNetworkProviders : [...prevState.selectedIpNetworkProviders,selectedOption],

  }))
}
handleOssProviderChange = (selectedOption) => {
  this.setState((prevState) => ({
    selectedOsProviders : [...prevState.selectedOsProviders,selectedOption],

  }))
}
handleMonitoringProviderChange = (selectedOption) => {
  this.setState((prevState) => ({
    selectedMonitoringProviders : [...prevState.selectedMonitoringProviders,selectedOption],
  }))
}

  handleClose = () => {
    this.setState({ anchor: false })
  }

  handleMainTabChange = (event, newValue) => {
    this.setState({ mainTabValue: newValue, subTabValue: 0, nestedTabValue: 0 });
  };

  handleSubTabChange = (event, newValue) => {
    this.setState({ subTabValue: newValue, nestedTabValue: 0 });
  };

  handleNestedTabChange = (event, newValue) => {
    this.setState({ nestedTabValue: newValue });
  };

  
  UserInfoNavigation = () => {
    this.setState({firstName:this.state.user_firstName , isAcceptNotification:this.state.user_isAcceptNotification, lastName:this.state.user_lastName , phoneNumber:this.state.user_phoneNumber, country:this.state.user_country,company:this.state.user_company})
    this.setState((prevState) => ({
      UserInfoFomrsClose: !prevState.UserInfoFomrsClose,
      UserForms: !prevState.UserForms,

    }));

  };
  Company_Info_changed = () => {
    this.setState((prevState) => ({
      Company_Info: !prevState.Company_Info,
      domain_profileClose: !prevState.domain_profileClose,


    }));
  }
  Technology_Stream_Changed = () => {
    this.setState((prevState) => ({
      domain_profileClose: !prevState.domain_profileClose,
      Technology_Stream: !prevState.Technology_Stream
    }))
  }

  Design_and_Sizing_Changed = () => {
    this.setState((prevState) => ({
      domain_profileClose: !prevState.domain_profileClose,
      DesignClose: !prevState.DesignClose
    }))
  }
  Domain_Technology_Changed = () => {
    this.setState((prevState) => ({
      domain_profileClose: !prevState.domain_profileClose,
      Domain_Technology: !prevState.Domain_Technology
    }))
  }


  handleSubmitUserDetails  = () => {
    const { firstName, lastName,company,phoneNumber, isAcceptNotification , country,email} = this.state;   
    const header = {
      "Content-Type": "application/json",
      token:this.state.token
    };

    const data = {
      first_name:firstName ,
      last_name: lastName,
      company: company,
      phone_number: phoneNumber,
      country: country,
      notification: isAcceptNotification,
      email:email
    };
    const httpBody = {
      profile: data ,
    };
    
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postProfileApiCalled = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // update ? 'bx_block_profile/profiles/update' :
       'bx_block_profile/profiles'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  updateUserDetails  = () => {
    const { firstName, lastName,company,phoneNumber, isAcceptNotification , country} = this.state; 
    const header = {
      "Content-Type": "application/json",
      token:this.state.token
    };


    const dataupdate = {
      first_name:firstName ,
      last_name: lastName,
      company: company,
      phone_number: phoneNumber,
      country: country,
      notification: isAcceptNotification,
    };

    const httpBody = {
      profile: dataupdate ,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postProfileApiCalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/update' 
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  uploadProfilePicture = async (fileData) => {
    this.setState({isModalOpen:false})
    const header = {
       token:this.state.token
    };
     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/upload_profile_photo'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const formData = new FormData();
    const fileBlob = new Blob([""], { type: fileData.type });
    const file = new File([fileBlob], fileData.name, {
      lastModified: fileData.lastModified,
      type: fileData.type,
    });
    formData.append("photo", file, file.name);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
 
  technologyDomainCompanyInfo = async () => {
  
    const header = {
      "Content-Type": "application/json",
      token:"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDgzLCJleHAiOjE3MDU1ODgwMjIsInRva2VuX3R5cGUiOiJsb2dpbiJ9.jmsVAtLzgdMTExs1hG72kCoN26KwOWfaisajKXOytotorpXfVSyGt8Jr3sb7KxdPRLQt-FTxX7kpZg6IOK6RXg"
    };
    const data = {
     
        type: "company_info",
        industry: this.state.selectedIndustry,
        department: this.state.selectedDepartment
     
    };
    const httpBody = {
      data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.domainProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/technology_domain_profiles'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateDesignAndSizing = async () => { 
    const {deployment_presence,data_stream,concurrent_users,metrics_retention,data_retention,raw_data_retention} = this.state
    const header = {
      "Content-Type": "application/json",
      token:this.state.token
    };
    const updateField = {
      deployment_point_of_presence: deployment_presence,
      data_stream_per_pop: data_stream,
      concurrent_users: concurrent_users,
      metrics_retention: metrics_retention,
      data_retention: data_retention,
      raw_data_retention: raw_data_retention
    };

    const httpBody = {
      profile: updateField ,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.designAndSizingApiCalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'update_design_and_sizing_information' 
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateTechnologyStream = async () => {
    const { selectedsuportedDeploy ,selectedDomainTech ,selectedDomainService,selectedPlatform} =this.state
    const header = {
      "Content-Type": "application/json",
      token:"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDgzLCJleHAiOjE3MDU1ODgwMjIsInRva2VuX3R5cGUiOiJsb2dpbiJ9.jmsVAtLzgdMTExs1hG72kCoN26KwOWfaisajKXOytotorpXfVSyGt8Jr3sb7KxdPRLQt-FTxX7kpZg6IOK6RXg"
    };
    const data = {
      supported_deployment: selectedsuportedDeploy,
      domain_technology: selectedDomainTech,
      domain_services: selectedDomainService,
      delivery_platform: selectedPlatform
    };
    const httpBody = {
      data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.technologyStreamApiCalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/technology_domain_profiles/update_technology_stream' 
      
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateDomainTechnologyProfile = async () => { 
    const {selectedRanProviders,selectedCoreProviders, selectedIpNetworkProviders,selectedOsProviders,selectedMonitoringProviders}  = this.state
    const header = {
      "Content-Type": "application/json",
      token:"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDc5LCJleHAiOjE3MDU1OTc0NjcsInRva2VuX3R5cGUiOiJsb2dpbiJ9.vjotQd1cmNPcOvRlg4x6LlwS-Di46IARQCZb8hZySRDbyALVUMiPdlNVAmq_T2-CcZJKWjtmRabHzwbGsbhXCg"
    };
    const data = {
      ran_providers: selectedRanProviders ,
      core_providers: selectedCoreProviders,
      ip_network_providers: selectedIpNetworkProviders,
      operating_system_providers: selectedOsProviders,
      management_and_monitoring_providers: selectedMonitoringProviders
    };

    const domainData = {
     data 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.technologyStreamApiCalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/technology_domain_profiles/update_domain_technology_profile' 
      
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(domainData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getProfileInformation = () => {
    const header = {
      token:this.state.token
     }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getCountriesApi = () => {
    const header = {
      token:this.state.token
     }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/countries_list`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  deleteImage = () => {
    const header = {
     token:this.state.token      
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    this.deleteImageApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/delete_profile_photo"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 


  // Customizable Area End
}
