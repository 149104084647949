export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const image_account=require('../assets/image_account.svg');
export const image_contracts=require('../assets/image_contracts.svg')

export const image_home=require('../assets/image_home.svg')
export const image_notifications=require('../assets/image_notifications.svg')

export const image_icon=require('../assets/image_icon.svg')

export const image_access=require('../assets/image_access.svg')

export const image_user=require('../assets/image_user.svg')

export const image_service=require('../assets/image_service.svg')

export const image_security=require('../assets/image_security.svg')
export const closeIcon = require('../assets/closeIcon.jpg')






export const generalLogo = require('../assets/image_logo.png');
export const image_logo = require("../assets/logoImage.png");
export const profile_image = require("../assets/image_profile.png")
export const Close = require("../assets/Wrong.png");


