import React from "react";

import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Avatar,
  Menu,
  MenuItem,
  TextField,
  InputLabel,
  Select
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Round, Vector, left, Account, contracts, home, ServicePlan, Notification, User, Security, Identity, Image_Logo } from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CfalertcreationController, {
  Props,
  configJSON,
} from "./CfalertcreationController";

export default class CfalertcreationWeb extends CfalertcreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={styles.mainBox}>
          <Box style={{ color: "white", fontSize: "36px" }}>
            <img style={{ width: "100%" }} src={Image_Logo}></img>
          </Box>
          <Box style={{ display: "flex", gap: "10%" }}>
            <Avatar alt="Cindy Baker" />
          </Box>
        </Box>

        <Box style={{ display: "flex" }}>
          <Tabs
            data-testId="isSelected"
            style={{ height: "100%", background: "black" }}
            value={this.state.isSelected}
            onChange={this.handleChange}
            indicatorColor="primary"
            orientation="vertical"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab style={{ background: "black", color: "white" }} label="Home" icon={<img src={home} />} />
            <Tab style={{ background: "black", color: "white" }} label="Account" icon={<img src={Account} />} />
            <Tab style={{ background: "black", color: "white" }} label="Contracts" icon={<img src={contracts} />} />
            <Tab style={{ background: "black", color: "white" }} label="Service Plan" icon={<img src={ServicePlan} />} />
            <Tab style={{ background: "black", color: "white" }} label="Notification" icon={<img src={Notification} />} />
          </Tabs>

          {this.state.isSelected === 1 && (<Box style={{ flexDirection: "row", background: "white", color: "black", height: "95vh", width: "300px" }}>
            <Box style={{ display: "flex", justifyContent: "end" }}><img data-testId="handleOpen" onClick={() => this.handleOpen()} src={left} /></Box>
            <Box style={{ display: "flex", height: "70px", justifyContent: "center", gap: "2%" }}>
              <Box style={{ display: "flex", gap: "1.7%", marginTop: "15px" }}>
                <img style={{ width: "10%", height: "35%" }} src={User} />
                <Typography style={{ fontSize: "14px" }}>User Information</Typography>
              </Box>
            </Box>
            <Box data-testId="handleIdentity" onClick={() => this.handleIdentity()} style={{ background: this.state.isIdentity === true ? "#1391AB" : "white", cursor: "pointer", display: "flex", height: "70px", justifyContent: "center", gap: "2%" }}>
              <Box style={{ display: "flex", gap: "1.7%", marginTop: "15px" }}>
                <img style={{ width: "10%", height: "35%" }} src={Identity} />
                <Typography style={{ fontSize: "14px" }}>Identity & Access Policies</Typography>
              </Box>
            </Box>
            <Box style={{ display: "flex", height: "70px", justifyContent: "center", gap: "2%" }}>
              <Box style={{ display: "flex", gap: "2%", marginTop: "15px" }}>
                <img style={{ width: "15%", height: "35%" }} src={Security} />
                <Typography style={{ fontSize: "14px" }}>Security</Typography>
              </Box>
            </Box>
          </Box>)}
          <Box>
            {
              this.state.isIdentity === true && (
                <Box style={{ marginLeft: "1%" }}>
                  <Tabs
                    data-testId="handleUserManagement"
                    style={{ background: "black", width: "76vw" }}
                    value={this.state.isUserManagement}
                    onChange={this.handleUserManagement}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab data-testId="handleManagement" onClick={() => this.handleManagement()} style={{ background: this.state.isManagement === true ? "#1391AB" : "black", color: "white" }} label="User Management" />
                    <Tab style={{ background: "black", color: "white" }} label="Group Management" />
                    <Tab style={{ background: "black", color: "white" }} label="Business Role" />
                    <Tab style={{ background: "black", color: "white" }} label="Application Role" />
                  </Tabs>
                </Box>
              )
            }
            {
              this.state.isManagement === true && (
                <>
                  <Box style={{ padding: "2%" }}>
                    <Box>
                      <Typography style={{ fontWeight: 700, fontSize: "25px" }}>Welcome to your account, Linda</Typography>
                      <Typography>Manage customer account, security, support level, and users. You can also check the emails received for your services.</Typography>
                    </Box>
                    <Box>
                      <Typography style={{ fontWeight: 700, fontSize: "25px" }}>Manage Users </Typography>
                      <Typography>View and manage Users, create additional with read or write access to your customer account</Typography>
                    </Box>
                  </Box>
                  {this.state.isInviteUser === false ? (
                    <Box>
                      <TableContainer component={Paper}>
                        <Table style={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow style={{ background: "#EDF9FC" }}>
                              <TableCell style={{ fontWeight: 700 }}>USER NAME</TableCell>
                              <TableCell align="right">BUSINESS ROLE</TableCell>
                              <TableCell align="right">APPLICATION ROLE</TableCell>
                              <TableCell align="right">STATUS</TableCell>
                              <TableCell align="right">ACTION</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <Typography>USER GROUP 1</Typography>
                            {this.state.rows.map((row: any) => (
                              <TableRow key={row.USERNAME}>
                                <TableCell style={{ fontWeight: 700 }} component="th" scope="row" > {row.USERNAME} </TableCell>
                                <TableCell align="right">{row.BUSINESSROLE}</TableCell>
                                <TableCell align="right">{row.APPLICATIONROLE}</TableCell>
                                <TableCell align="right"><Button style={{ background: "#1391AB", width: "50%", borderRadius: "25px" }}>{row.STATUS}</Button></TableCell>
                                <TableCell align="right"><img src={Round} onClick={this.handleRoundImage} /></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableBody>
                            <Typography>USER GROUP 2</Typography>
                            {this.state.rowsData.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage).map((row: any) => (
                              <TableRow key={row.USERNAME}>
                                <TableCell style={{ fontWeight: 700 }} component="th" scope="row" > {row.USERNAME} </TableCell>
                                <TableCell align="right">{row.BUSINESSROLE}</TableCell>
                                <TableCell align="right">{row.APPLICATIONROLE}</TableCell>
                                <TableCell align="right"><Button style={{ background: row.STATUS === "Pending" ? "#A8B1B3" : "#1391AB", width: "50%", borderRadius: "25px" }}>{row.STATUS}</Button></TableCell>
                                <TableCell align="right"><img src={Round} onClick={this.handleRoundImage} /></TableCell>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={this.state.RoundImage}
                                  open={this.state.RoundImage}
                                  onClose={this.handleCloseModal}
                                >
                                  <MenuItem onClick={this.handleCloseModal}><EditIcon /> Edit</MenuItem>
                                  <MenuItem onClick={this.handleCloseModal} style={{ color: "red" }}><DeleteOutlineIcon style={{ fill: "red" }} />Delete</MenuItem>
                                </Menu>
                              </TableRow>
                            ))}

                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box style={{ display: "flex", justifyContent: "space-between", padding: "2%" }}>
                        <Box>
                          <TablePagination
                            component="div"
                            rowsPerPageOptions={[2, 4, 5]}
                            count={this.state.rowsData.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                          /></Box>
                        <Box>
                          <Button onClick={this.handleInviteUser} style={{ background: "#1391AB" }}><img src={Vector} />Invite user</Button>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Box style={{ display: "flex" }}>
                        <Box>
                          <Box style={styles.inputlablepassword}>
                            <InputLabel style={styles.lableStyle}>User Name
                            </InputLabel>
                          </Box>
                          <Box style={styles.emailPAswordRoot}>
                            <TextField placeholder=" User Name" data-testId="setPassword"
                              style={{ borderBottom: "none", padding: "9px 0 7px 0", border: '1px solid #70b3b7', borderRadius: "4px", width: "25vw", background: "white" }}>
                            </TextField>
                          </Box>
                        </Box>
                        <Box>
                          <Box style={styles.inputlablepassword}>
                            <InputLabel style={styles.lableStyle}>Email address
                            </InputLabel>
                          </Box>
                          <Box style={styles.emailPAswordRoot}>
                            <TextField placeholder=" Email" data-testId="setPassword"
                              style={{ borderBottom: "none", padding: "9px 0 7px 0", border: '1px solid #70b3b7', borderRadius: "4px", width: "25vw", background: "white" }}>
                            </TextField>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box style={styles.inputlablepassword}>
                          <InputLabel style={styles.lableStyle}>User Group
                          </InputLabel>
                        </Box>
                        <Box>
                          <Select />
                        </Box>
                      </Box>
                    </>
                  )}

                </>
              )
            }
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = ({
  lableStyle: { fontWeight: 600, color: "black", fontFamily: "Roboto Condensed" },
  emailPAswordRoot: { display: "flex", marginTop: "15px", height: "2.7em" },
  inputlablepassword: { display: "flex", marginTop: "20px" },
  buttonroot: { display: "flex", marginTop: "32px", justifyContent: "center" },
  typographyRoot: { fontFamily: "Roboto Condensed" },
  checkBoxRoot: { display: "flex", marginTop: "1%", marginLeft: "-10px", alignItems: "center" },
  mainBox: { background: "white", padding: "19px 24px", display: "flex", justifyContent: "space-between" }
})
// Customizable Area End
