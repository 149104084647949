export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const left = require("../assets/left.png");
export const Account = require("../assets/Account.png");
export const contracts = require("../assets/contracts.png");
export const home = require("../assets/home.png");
export const Notification = require("../assets/Notification.png");
export const ServicePlan = require("../assets/ServicePlan.png");
export const User = require("../assets/User.png");
export const Identity = require("../assets/Identity.png");
export const Security = require("../assets/Security.png");
export const Vector = require("../assets/Vector.png");
export const Image_Logo = require("../assets/Image_Logo.png");
export const Round = require("../assets/Round.png");


