import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    InputLabel,
    Modal,
    InputAdornment,
    IconButton,
    TextField,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CommonComponent from "./CommonComponent.web";
import { Link } from "react-router-dom";
import { Wrong, ValidIcon, InValidIcon } from "./assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";
import SignupLoginTab from "./SignupLoginTab.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class CreatePassword extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {

        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <ThemeProvider theme={theme}>
                    <Box className="mainRoot">
                        <CommonComponent navigation={this.props.navigation} id={""} />
                        <Box className="rootbox">
                            <Box className="boxRoot">
                                <Box className="welcomeBox">
                                    Welcome!
                                </Box>
                                <SignupLoginTab navigation={this.props.navigation} id={""}/>
                                {this.state.error && (
                                    <div className="error-popup" style={{ color: '#8D0707', backgroundColor: '#FEE2E2', borderLeft: 'solid 4px #DC2626', padding: '7px 10px 7px 12px' }}>
                                        {this.state.error}
                                    </div>
                                )}
                                <Modal
                                    data-testId="handleCloseModal"
                                    open={this.state.openModal}
                                    onClose={() => this.handleCloseModal()}>
                                    <Box className="webStyle">
                                        <Box sx={{ display: "flex", justifyContent: "end" }}> 
                                            <img data-testId="handleCloseModalIcon" onClick={() => this.handleCloseModal()} src={Wrong} />
                                        </Box>
                                        <Typography className="webStyle1">Thank you</Typography>
                                        <Typography style={{ marginTop: "5%" }}>You have successfully created your Account.</Typography>
                                        <Box className="buttonroot1">
                                            <Box style={{ display: "flex", width: "100%" }}>
                                                <Button data-testId="handleCloseModalBTN" style={{ background: "#1391AB", borderRadius: "8px", width: "100%", textTransform: "inherit", color: "white" }} onClick={() => this.handleCloseModal()}>Done</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Modal>
                                {!this.state.passwordsMatch && (
                                    <Box className="error-popup" style={{ color: '#8D0707', backgroundColor: '#FEE2E2', borderLeft: 'solid 4px #DC2626', padding: '7px 10px 7px 12px' }}>
                                        <Typography className="error-text">Incorrect password</Typography>
                                    </Box>
                                )}
                                <Box className="inputlablepassword">
                                    <InputLabel className="lableStyle">Password
                                    </InputLabel>
                                </Box>
                                <Box className="inputemail">
                                    <TextField data-testId="TextFieldhandleClickShowPassword"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        data-testid="handleClickShowPassword"
                                                        style={{ marginRight: "2px" }}
                                                        aria-label="toggle password visibility"
                                                        onClick={() => this.handleClickShowPassword()}
                                                        edge="end"
                                                    >
                                                        {this.state.enablePasswordField ? (
                                                            <Visibility
                                                                data-testid="enablePasswordField" style={{ color: "#94A3B8" }} />
                                                        ) : (
                                                            <VisibilityOff style={{ color: "#94A3B8" }} />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder=" Enter your password" value={this.state.password}
                                        onChange={(e: any) => this.setPassword(e?.target?.value)}
                                        className="psw-input"
                                        type={this.state.enablePasswordField ? "text" : "password"} >

                                    </TextField>
                                </Box>
                                <Box className="inputlablepassword">
                                    <InputLabel className="lableStyle">Confirm Password
                                    </InputLabel>
                                </Box>
                                <Box data-testId="textinputtest"  className="inputemail">
                                    <TextField
                                       error={true}
                                       data-testId="setConfirmPassInput"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        style={{ marginRight: "2px" }}
                                                        aria-label="toggle password visibility"
                                                        edge="end"
                                                        onClick={() => this.handleClickShowConfirmPassword()}
                                                    >
                                                        {this.state.enableConfirmPasswordField ? (
                                                            <Visibility style={{ color: "#94A3B8" }} />
                                                        ) : (
                                                            <VisibilityOff style={{ color: "#94A3B8" }} />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} placeholder=" Confirm your password"
                                        value={this.state.confirmPassword} onChange={(e: any) => this.setConfirmPassword(e.target.value)}
                                        type={this.state.enableConfirmPasswordField ? "text" : "password"}
                                        className='psw-input' style={{border:!this.state.passwordsMatch ?  '1px solid var(--Status-Red-400, #F87171)': ''}}>
                                    </TextField>
                                </Box>
                                <Box>
                                    <ul style={{ listStyle: "none", paddingLeft: 0, }}>
                                        <li>{/[A-Z]/.test(this.state.password) ? <img src={ValidIcon} /> : <img src={InValidIcon} />} At least one capital letter </li>
                                        <li>{/[a-z]/.test(this.state.password) ? <img src={ValidIcon} /> : <img src={InValidIcon} />} At least one lowercase letter</li>
                                        <li>{/\d/.test(this.state.password) ? <img src={ValidIcon} /> : <img src={InValidIcon} />} At least one number</li>
                                        <li>{this.state.password?.length >= 8 ? <img src={ValidIcon} /> : <img src={InValidIcon} />} Minimum character length is 8 characters</li>
                                    </ul>
                                </Box>
                                {/* <Box className="buttonroot"> */}
                                    <Box className="buttonroot">
                                        <Button data-testId="handleSignUp" className="signupBtn" onClick={() => { this.handleResetPassword(); }}
                                        >Sign up</Button>
                                    </Box>
                                {/* </Box> */}
                                <Box>
                                    <Typography className="signupAccountStyle">Have an account?<Link className="loginBtn" to="/EmailAccountLoginBlock">Login</Link></Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
// Customizable Area End
