export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const image_logo = require("../assets/image_logo.png");

export const Shape = require("../assets/Shape.png");
export const youtube = require("../assets/youtube.png");
export const Google = require("../assets/Google.png");
export const Facebook = require("../assets/Facebook.png");
export const Twitter = require("../assets/Twitter.png");
export const InValidIcon = require("../assets/InValid.svg");
export const ValidIcon = require("../assets/Valid.svg");
export const Wrong = require("../assets/Wrong.png");



