import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";

// Customizable Area End


export default class SignupLoginTab extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {

        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Box data-testId="handlSignupPage"
                    className="boxRoot6">
                    <Box className="boxRoot7">
                        <Box
                            className="boxRoot9">
                            <Box className="signup-box"
                                data-testId="handlSignupPage2"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingRight: '20px',
                                    background: this.state.buttonChange === "SignUp" ? "#1391AB" : "white",
                                }} onClick={() => this.handlSignupPage()}>
                                <Typography style={{ fontWeight: 700 }} className="signup-box-text">Sign up </Typography>
                            </Box>
                            <Box className="handleLoginPage" data-testId="handleLoginPage"
                                style={{
                                    background: this.state.buttonChange === "Login" ? "#1391AB" : "white",
                                }}
                                onClick={() => this.handleLoginPage()}><Typography style={{ fontWeight: 700 }} className="signup-box-text">Login</Typography></Box>
                        </Box>
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
// Customizable Area End
