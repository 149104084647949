import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import LoginPage from "./LoginPage.web";
import SignUpPage from "./SignUpPage.web";
import CommonComponent from "./CommonComponent.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box  className="mainRoot">
          <CommonComponent navigation={this.props.navigation} id={""} />
          <Box className="rootbox">
            <Box className="boxRoot">
              {
                this.state.buttonChange === "Login" ? (
                  <Box className="welcomeBox">
                    Welcome back!
                  </Box>
                ) : (
                  <Box className="welcomeBox">
                    Welcome!
                  </Box>
                )
              }
              <Box data-testId="boxRoot" className="buttonboxRoot">
                <Box className="boxRootsignup">
                  <Box className="boxRoot9">
                    <Box className="singup-box" data-testId="handlSignupPage" style={{
                      color: this.state.buttonChange !== "Login" ? "white" : "black",
                      background: this.state.buttonChange !== "Login" ? "#1391AB" : "white",
                     }} onClick={() => this.handlSignupPage()}>
                      <Typography style={{fontWeight: 700}} className="singup-box-text">Sign up</Typography>
                    </Box>

                    <Box className="signup-box" style={{
                      color: this.state.buttonChange === "Login" ? "white" : "black",
                      background: this.state.buttonChange === "Login" ? "#1391AB" : "white",
                    }}
                      data-testId="btnEmailLogIn"
                      onClick={() => this.handleLoginPage()}><Typography style={{fontWeight: 700}} className="signup-box-text">Login</Typography></Box>
                  </Box>
                </Box>
              </Box>
              {this.state.buttonChange === "Login" &&
                <LoginPage navigation={this.props.navigation} id={""} />
              }
              {this.state.buttonChange === "SignUp" &&
                <SignUpPage navigation={this.props.navigation} id={""} />
              }

            </Box>
          </Box>
        </Box>
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End