import React, { useState } from 'react';
import { makeStyles, Drawer, List, ListItem, ListItemText, Typography, Box } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import FolderIcon from '@material-ui/icons/Folder';
import WidgetsIcon from '@material-ui/icons/Widgets';
import LockIcon from '@material-ui/icons/Lock';
// import UserIcon from '@material-ui/icons'
import User from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person';
import LockPersonIcon from '@material-ui/icons/Lock';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { image_contracts,image_service,image_access,image_security } from './assets';


interface Props {
    isNestedOpenBar?: boolean;
    isNestedOpen?:boolean;
    nestedTabs?:any;
    headerTab?:any;
    setHeaderText?:string;
    nestedHeaderText?:string;

}

export default class Header extends React.Component<Props>{
  nestedLinks = [
    { text: 'User Information', href: '/GeneralInformation', Icon: <HomeIcon /> },
    { text: 'Identity & Access Policies', href: '/Cfcustomisableworkflowbyusers' , Icon: <img src={image_access} /> },
    { text: 'Security', href: '#',Icon: <img src={image_security} /> },
    // Add more links as needed
  ];
  
  headerTabs = [
    { text: 'General Information', href: '/GeneralInformation'  },
    { text: 'Technology Domain Profile', href: '/GeneralInformationTechnologyomainProfile' },
  ];
    
  constructor(props:Props) {
    super(props);

    this.state = {
      isNestedOpen: false,
      nestedTabs:this.nestedLinks,
      headerTab:this.headerTabs,
      setHeaderText:"General Information",
      nestedHeaderText:"User Information",
    };
  }



  toggleNestedSidebar = (tabName:any) => {
    if (tabName === 'Account') {
      this.setState({
        nestedTabs: this.nestedLinks,
      });
    }
   this.setState({isNestedOpen:true})
  };

  setHeaderTabs = (tabName:any) => {
    if (tabName === 'User Information') {
      this.setState({
        headerTab: this.headerTabs,
      });
    }
  };

  headerNavigation = (tabName:any) => {
    if (tabName === 'General Information') {
      this.setState({setHeaderText:tabName})
    }else{
      this.setState({setHeaderTabs:tabName})
    }
  };

render() {
  console.log("isNestedOpenBar" , this.props.isNestedOpenBar)
 //@ts-expect-error
  const { isNestedOpen, nestedTabs, headerTab, setHeaderText , nestedHeaderText} = this.state;
return(
  <>
      
  <div className={isNestedOpen ? "custom-top-nav":"custom-top-navfull"}>
  {headerTab.map((link:any, index:any) => (
    
      <a key={index} href={link.href}  className= { `sidebar-link item ${setHeaderText === link.text && "active"}` } onClick={()=>{this.headerNavigation(link.text)}}>
        {link.text}
    {console.log(link.text,"activetabs",setHeaderText)}

      </a>
    ))}
</div>
<div className="main-sidebar">
    <div className='main-sidebar-item'>
  <HomeIcon />
        
  <a href="#" className="sidebar-link" onClick={()=>this.toggleNestedSidebar("Home")}>
  Home
  </a>
    </div>
    <div className='main-sidebar-item active'>
    < FolderIcon />

  <a href="#" className="sidebar-link" onClick={()=>this.toggleNestedSidebar("Account")}>
    Account
  </a>
  </div>

  <div className='main-sidebar-item'>

  <img src={image_contracts} />
  <a href="#" className="sidebar-link" onClick={()=>this.toggleNestedSidebar("Contracts")}>
  Contracts
  </a>
 
 
  </div>

  <div className='main-sidebar-item'>

  <img src={image_service} />

  <a href="#" className="sidebar-link" onClick={()=>this.toggleNestedSidebar(" Service Plans")}>
  Service Plans
  </a>
  </div>

  <div className='main-sidebar-item'>
  <WbSunnyIcon />

  <a href="#" className="sidebar-link"onClick={()=>this.toggleNestedSidebar("Notification")}>
  Notification
  </a>
  </div>

  {/* ... other main sidebar links */}
</div>
<div className={`nested-sidebar ${isNestedOpen ? 'open' : ''}`}>
  <div className="toggle-nested" onClick={()=>this.setState({isNestedOpen:false})}>
    {isNestedOpen && <ChevronLeftIcon/>}
  </div>
 
  {nestedTabs.map((link:any, index:any) => (
      <a key={index} href={link?.href } className={`sidebar-link ${link.text===nestedHeaderText && 'active'}`}  onClick={()=>this.setHeaderTabs(link.text)}>
        {link.Icon}
        {link.text}
      </a>
    ))}
</div>

</>
  )
}
  
  
}
