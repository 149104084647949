import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Account, contracts, home, ServicePlan, Notification } from "./assets";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  page: number,
  rowsPerPage: number,
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  anchor: boolean;
  tabData: any;
  RoundImage: any;
  rows: any;
  rowsData: any;
  isSelected: number;
  isUserManagement: number;
  isManagement: boolean;
  isIdentity: boolean;
  isInviteUser: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfalertcreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      page: 0,
      rowsPerPage: 5,
      isIdentity: true,
      txtInputValue: "",
      isManagement: true,
      txtSavedValue: "A",
      enableField: false,
      anchor: false,
      isUserManagement: 0,
      isSelected: 1,
      RoundImage: null,
      tabData: [
        { image: home, label: 'Home' },
        { image: Account, label: 'Account' },
        { image: contracts, label: 'Contracts' },
        { image: ServicePlan, label: 'Service Plans' },
        { image: Notification, label: 'Notification' },
      ],
      rows: [
        { id: 1, USERNAME: 'NGM-Support', BUSINESSROLE: 'BUSINESS ROLE 1 ', APPLICATIONROLE: 'APPLICATION ROLE 1', STATUS: 'Enabled', },
      ],
      rowsData: [
        { id: 2, USERNAME: 'NGM-Devops-Admin', BUSINESSROLE: 'BUSINESS ROLE 2', APPLICATIONROLE: 'APPLICATION ROLE 2', STATUS: 'Enabled', },
        { id: 3, USERNAME: 'NGM-Devops-aaaaaaaaaa', BUSINESSROLE: 'BUSINESS ROLE 3', APPLICATIONROLE: 'APPLICATION ROLE 3', STATUS: 'Pending', },
        { id: 4, USERNAME: 'NGM-Devops-Consultant', BUSINESSROLE: 'BUSINESS ROLE 3', APPLICATIONROLE: 'APPLICATION ROLE 3', STATUS: 'Pending', },
        { id: 5, USERNAME: 'NGM-Devops-ddddddddddddddd', BUSINESSROLE: 'BUSINESS ROLE 3', APPLICATIONROLE: 'APPLICATION ROLE 3', STATUS: 'Pending', },
        { id: 6, USERNAME: 'NGM-Devops-sssssssssss', BUSINESSROLE: 'BUSINESS ROLE 3', APPLICATIONROLE: 'APPLICATION ROLE 3', STATUS: 'Pending', },
        { id: 7, USERNAME: 'NGM-Devops-jjjjjjjjjjjj', BUSINESSROLE: 'BUSINESS ROLE 3', APPLICATIONROLE: 'APPLICATION ROLE 3', STATUS: 'Pending' },
        { id: 8, USERNAME: 'NGM-Devops-rrrrrrrrrrrrrrrr', BUSINESSROLE: 'BUSINESS ROLE 3', APPLICATIONROLE: 'APPLICATION ROLE 3', STATUS: 'Pending' },

      ],
      isInviteUser: false
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  // Customizable Area Start
  handleOpen = () => {
    this.setState({ anchor: true })
  }

  handleClose = () => {
    this.setState({ anchor: false })
  }

  handleChange = (event: any, index: any) => {
    this.setState({ isSelected: index });
  };
  handleIdentity = () => {
    this.setState({ isIdentity: true });
  }
  handleUserManagement = (event: any, index: any) => {
    this.setState({ isUserManagement: index });
  }
  handleManagement = () => {
    this.setState({ isManagement: true })
  }
  handleChangePage = (event: any, newPage: any) => {
    this.setState({ page: newPage })
  };
  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })
  };

  handleRoundImage = (event: any) => {
    this.setState({ RoundImage: event.currentTarget })
  }
  handleCloseModal = () => {
    this.setState({ RoundImage: null })
  }
  handleInviteUser = () => {
    this.setState({ isInviteUser: true })
  }

  // Customizable Area End
}
